/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_AdminDashboard_AdminKeyContactsDto } from '../models/Wealthlane_CustomerService_Dtos_AdminDashboard_AdminKeyContactsDto';
import type { Wealthlane_CustomerService_Dtos_AdminDashboard_ParticipantActivitiesDto } from '../models/Wealthlane_CustomerService_Dtos_AdminDashboard_ParticipantActivitiesDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CreateCustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CreateCustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDeactivationDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDeactivationDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserReActivationDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserReActivationDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_GetAllCustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_GetAllCustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_GetCustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_GetCustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerUserService {

    /**
     * @param statuses 
     * @param isEnabled 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUserList(
statuses?: Array<number>,
isEnabled?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user-list',
            query: {
                'Statuses': statuses,
                'IsEnabled': isEnabled,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserResendEmailToCustomerUser(
customerUserId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user/resend-email-to-customer-user/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param roleId 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_GetCustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUserListByRoleId(
roleId: string,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerUser_GetCustomerUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user-list-by-role-id/{roleId}',
            path: {
                'roleId': roleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_CreateCustomerUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user/customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerUserCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-user/customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUser(): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUserById(
customerUserId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user-by-id/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUserForUpdateById(
customerUserId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user-for-update-by-id/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUserStatusList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-user-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserRemarks(
customerUserId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/remarks/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_GetAllCustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserCustomerUsers(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerUser_GetAllCustomerUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/customer-users',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserDeactivateCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDeactivationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user/deactivate-customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserReActivateCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserReActivationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user/re-activate-customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserDeactivationRemarks(
customerUserId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/deactivation-remarks/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns number Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserSystemApprovalRequest(
companyId: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/system-approval-request/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns number Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserParticipantApprovalRequest(
companyId: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/participant-approval-request/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_CustomerService_Dtos_AdminDashboard_ParticipantActivitiesDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserParticipantRegistered(
companyId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_AdminDashboard_ParticipantActivitiesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/participant-registered/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_CustomerService_Dtos_AdminDashboard_AdminKeyContactsDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserAdminKeyContacts(
companyId: string,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_AdminDashboard_AdminKeyContactsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user/admin-key-contacts/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @param permissionName 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserCheckUserPermission(
userId: string,
permissionName?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user/check-user-permission/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'permissionName': permissionName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
