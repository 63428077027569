/* eslint-disable camelcase */
import _, { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { FILE_SIZE, PDF_SUPPORTED_FORMATS, SUPPORTED_FORMATS } from '../../constants/common';
import { GlobalValidation } from './commonValidation';
import { commonValidation, regExp } from './validationRule';
import { type Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto } from '../../services/wealthlane-tax-services';
import CheckFieldLevelPermissions from '../permission/checkFieldLevelPermission';

const URLRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const greaterThenZero = /^0*?[1-9]\d*$/;

export const COMPANY_PROFILE_FORM_VALIDATION = Yup.object().shape({
  companyName: GlobalValidation.Name,
  ticker: commonValidation({ required: true, textLimit: 4, noBlank: true }).matches(
    /^[a-zA-Z ]*$/,
    'Invalid Ticker'
  ),
  primaryStockExchangeId: commonValidation({ required: true, textLimit: 5000, noBlank: true }),
  taxId: commonValidation({
    required: true,
    digits: true,
    min: 9,
    max: 9,
    minValue: 9,
    noBlank: true,
  }),
  businessDescription: commonValidation({ required: false, textLimit: 5000, noBlank: true }),
  websiteUrl: commonValidation({ required: true, textLimit: 5000, noBlank: true }).matches(
    URLRegExp,
    'Invalid Website URL'
  ),
  domainNames: Yup.array().min(1, 'Required field'),
  cusip: commonValidation({ required: false, textLimit: 200, noBlank: true }),
  sicCode: commonValidation({ required: false, textLimit: 200, noBlank: true }),
  zipCode: GlobalValidation.ZipCode,
});

export const ROLES_VALIDATION = Yup.object().shape({
  roleName: GlobalValidation.Name,
  description: commonValidation({ required: false, textLimit: 100 }),
});

export const DEPARTMENT_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  description: GlobalValidation.Description,
});

export const JOB_TITLE_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  description: GlobalValidation.Description,
});

export const EMPLOYEE_TYPE_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  description: GlobalValidation.Description,
});

export const PARTICIPANT_DETAILS_VALIDATION = Yup.object().shape({
  firstName: commonValidation({ required: true, textLimit: 100, noBlank: true }),
  middleName: commonValidation({ required: false, textLimit: 100, noBlank: true }),
  lastName: commonValidation({ required: true, textLimit: 100, noBlank: true }),
  dateOfBirth: commonValidation({ required: true }),
  genderId: commonValidation({ required: true }),
  maritalStatusId: commonValidation({ required: true }),
  currentLegalResidenceId: commonValidation({ required: true, textLimit: 100 }),
  residenceStatusId: commonValidation({ required: false, textLimit: 100 }),
  phoneNumber: commonValidation({ required: true, textLimit: 100 }),
  homeNumber: commonValidation({ required: true, textLimit: 100 }),
  email: commonValidation({ required: true, email: true }),
});

export const PARTICIPANT_TAX_DETAILS_VALIDATION = Yup.object().shape({
  fullLegalName: commonValidation({ required: true, textLimit: 100, noBlank: true }),
  ssn: commonValidation({ required: true, textLimit: 100, noBlank: true }),
  countryLegalResidencyId: commonValidation({ required: true }),
  governmentDocumentTypeId: commonValidation({ required: true }),
  governmentDocumentIssuedCountryId: commonValidation({ required: true }),
  governmentDocumentIdIssuedCityId: commonValidation({ required: true }),
  governmentDocumentIdIssuedStateId: commonValidation({ required: true }),
  governmentDocumentIdNo: commonValidation({ required: true, textLimit: 100 }),
  residenceStatusId: commonValidation({ required: false, textLimit: 100 }),
  governmentDocumentIdIssueDate: commonValidation({ required: true, textLimit: 100 }),
  governmentDocumentIdZipOrPostalCode: commonValidation({ required: true, textLimit: 100 }),
});

export const ELECTION_CONFIGURATION = Yup.object().shape({
  startFromVestingDateInDays: commonValidation({ required: true, digits: true }).matches(
    greaterThenZero,
    'Must be greater then 0'
  ),
  endFromVestingDateInDays: commonValidation({ required: true, digits: true })
    .matches(greaterThenZero, 'Must be greater then 0')
    .when('startFromVestingDateInDays', (start: any, schema: any) => {
      if (!isEmpty(start)) {
        return schema.test({
          test: (end: any) => {
            return parseInt(start) >= parseInt(end);
          },
          message: 'Should be less than start days.',
        });
      }
    }),
  settlementMethod: commonValidation({ required: true }).when('settlementValidity', {
    is: (status: string) => {
      if (status === 'invalid') {
        return true; // validate and go to then function
      }
    },
    otherwise: (d: any) => commonValidation({ required: false }),
  }),
});

export const SOP_ELECTION_VALIDATION = Yup.object().shape({
  settlementMethod: commonValidation({ required: true }).when('settlementValidity', {
    is: (status: string) => {
      if (status === 'invalid') {
        return true; // validate and go to then function
      }
    },
    otherwise: (d: any) => commonValidation({ required: false }),
  }),
});

export const PRE_CLEARANCE_PERIOD_CONFIG = Yup.object().shape({
  transactionPeriod: commonValidation({ required: true, digits: true, noBlank: true }),
  transactionPeriodTypeId: commonValidation({ required: true }),
});

export const PARTICIPANT_EMPLOYMENT_DETAILS_VALIDATION = Yup.object().shape({
  currentEmployment: Yup.object().shape({
    employeeId: commonValidation({ required: true, textLimit: 100, noBlank: true }),
    employerName: commonValidation({ required: true, textLimit: 100, noBlank: true }),
    employmentCountryId: Yup.string().nullable(),
    employmentStateId: Yup.string().nullable(),
    employmentCityId: Yup.string().nullable(),
    employmentState: Yup.string().nullable(),
    employmentCity: Yup.string().nullable(),
    jobTitle: Yup.string().nullable(),
    workEmail: Yup.string().email().nullable(),
    costCentre: Yup.string().nullable(),
    hireDate: commonValidation({ required: true }),
    employmentAddressLine1: Yup.string().nullable(),
    employmentAddressLine2: Yup.string().nullable(),
    employmentZipPostalCode: commonValidation({
      required: false,
      digits: true,
      noBlank: true,
      min: 5,
      max: 5,
    }),
  }),
  previousEmployments: Yup.array().of(
    Yup.object().shape({
      jobTitle: commonValidation({ required: true }).nullable(),
      hireDate: commonValidation({ required: true }).nullable(),
      endDate: commonValidation({ required: true }).nullable(),
    })
  ),
});

export const PARTICIPANT_ADDRESS_DETAILS_VALIDATION = {
  currentHomeAddress: Yup.object().shape({
    startDate: commonValidation({ required: true }),
    // startDate: Yup.string().required('Required field').nullable(),
    addressLine1: commonValidation({ required: true }),
    // addressLine1: Yup.string().required('Required field').nullable(),
    addressLine2: commonValidation({ required: true }),
    // addressLine2: Yup.string().required('Required field').nullable(),
    countryId: commonValidation({ required: true }),
    // countryId: Yup.string().required('Required field').nullable(),
    zipCode: commonValidation({ required: true, digits: true, noBlank: true, min: 5, max: 5 }),
    // zipCode: Yup.string()
    //   .required('Required field')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
    //   .min(5, 'Must be 5 digits')
    //   .max(5, 'Must be 5 digits')
    //   .nullable(),
  }),
  mailingAddress: Yup.object().shape({
    addressLine1: commonValidation({ required: true }),
    // addressLine1: Yup.string().required('Required field').nullable(),
    addressLine2: commonValidation({ required: true }),
    // addressLine2: Yup.string().required('Required field').nullable(),
    countryId: commonValidation({ required: true }),
    // countryId: Yup.string().required('Required field').nullable(),
    zipCode: commonValidation({ required: true, digits: true, noBlank: true, min: 5, max: 5 }),
    // zipCode: Yup.string()
    //   .required('Required field')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
    //   .min(5, 'Must be 5 digits')
    //   .max(5, 'Must be 5 digits')
    //   .nullable(),
  }),
  previousAddresses: Yup.array().of(
    Yup.object().shape({
      startDate: commonValidation({ required: true }),
      // startDate: Yup.string().required('Required field').nullable(),
      endDate: commonValidation({ required: true }),
      // endDate: Yup.string().required('Required field').nullable(),
      addressLine1: commonValidation({ required: true }),
      // addressLine1: Yup.string().required('Required field').nullable(),
      addressLine2: commonValidation({ required: true }),
      // addressLine2: Yup.string().required('Required field').nullable(),
      countryId: commonValidation({ required: true }),
      // countryId: Yup.string().required('Required field').nullable(),
      zipCode: commonValidation({ required: true, digits: true, noBlank: true, min: 5, max: 5 }),
      // zipCode: Yup.string()
      //   .required('Required field')
      //   .matches(/^[0-9]+$/, 'Must be only digits')
      //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
      //   .min(5, 'Must be 5 digits')
      //   .max(5, 'Must be 5 digits')
      //   .nullable(),
    })
  ),
};

export const PARTICIPANT_VALIDATION = (isEdit: boolean): any => ({
  email:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeEmail', isEdit))
      ? null
      : GlobalValidation.Email,
  payrollId: GlobalValidation.RequiredNoBlankAlphanumeric,
  employeeId:
    isEdit && Boolean(CheckFieldLevelPermissions('Employee', isEdit))
      ? null
      : GlobalValidation.RequiredNoBlankAlphanumeric,
  firstName:
    isEdit && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : GlobalValidation.Name.matches(/^[a-z,0-9, @()-/*"':;_#-+]+$/i, 'Invalid First Name'),
  middleName:
    isEdit && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : commonValidation({
          required: false,
          textLimit: 100,
          noBlank: true,
          doubleSpace: true,
        }).matches(/^[a-z,0-9, @()-/*"':;_#-+]+$/i, 'Invalid Middle Name'),
  lastName:
    isEdit && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : GlobalValidation.Name.matches(/^[a-z,0-9, @()-/*"':;_#-+]+$/i, 'Invalid Last Name').label(
          'Last Name'
        ),
  hireDate: Yup.date().required('Required field').typeError('Required field'),
  employeeTypeId: commonValidation({ required: true }),
  countryId: commonValidation({ required: true }),
  costCenter:
    isEdit && Boolean(CheckFieldLevelPermissions('CostCenter', isEdit))
      ? null
      : GlobalValidation.RequiredNoBlankAlphanumeric,
  ssn: /* isEdit && Boolean(CheckFieldLevelPermissions('ssn', isEdit))
      ? null
      : */ commonValidation({ required: true, numericPhone: true })
    .min(11, 'Must be 9 digits')
    .max(11, 'Must be 9 digits')
    .when(['residentStatusName', 'participantOtherCountry'], {
      is: (residentStatus: string, participantOtherCountry: boolean) => {
        if (residentStatus === 'Non Resident Alien' && participantOtherCountry === false) {
          return true; // validate and go to then function
        } else if (residentStatus !== 'Non Resident Alien') {
          return true; // validate and go to then function
        }
      },
      otherwise: (d: any) =>
        Yup.string().min(11, 'Must be 9 digits').max(11, 'Must be 9 digits').nullable(),
    }),
  titleId:
    isEdit && Boolean(CheckFieldLevelPermissions('JobTitle', isEdit))
      ? null
      : commonValidation({ required: true }),
  residentStatusId: commonValidation({ required: true }),
  divisionId:
    isEdit && Boolean(CheckFieldLevelPermissions('Department', isEdit))
      ? null
      : commonValidation({ required: true }),
  workNumber:
    isEdit && Boolean(CheckFieldLevelPermissions('PhoneNumber', isEdit))
      ? null
      : GlobalValidation.NonRequiredPhone.min(12, 'Must be 10 digits').when('isOtherCountryPhone', {
          is: false,
          otherwise: (d: any) => GlobalValidation.NonRequiredPhone,
        }),
  employmentZipCode:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : commonValidation({
          required: true,
          digits: true,
        })
          .min(5, 'Must be 5 digits')
          .max(5, 'Must be 5 digits')
          .typeError('Must be only digits')
          .when('zipCodeErrorMessage', (value: any, schema: any) => {
            if (value != null && value != undefined) {
              return schema.test(
                'zipCodeValidation', // Name for the test
                value, // Custom error message
                (val: any) => val === true // Test function
              );
            }
            return schema;
          })
          .when('isOtherCountry', {
            is: false,
            otherwise: (d: any) =>
              commonValidation({
                required: true,
              }),
          }),
  employmentAddressLine1:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : GlobalValidation.AddressLine1,
  employmentAddressLine2:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : GlobalValidation.AddressLine2,
  employmentCountryId:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : commonValidation({ required: true }),
  employmentStateId:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : commonValidation({ required: true }).when('isOtherCountry', {
          is: false,
          otherwise: (d: any) => Yup.string().nullable(),
        }),
  employmentCityText:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : commonValidation({ required: true, noBlank: true, doubleSpace: true }),
  employmentStateText:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeWorkAddress', isEdit))
      ? null
      : commonValidation({ required: true, noBlank: true, doubleSpace: true }).when(
          'isOtherCountry',
          {
            is: true,
            otherwise: (d: any) => Yup.string().nullable(),
          }
        ),
  dateOfBirth:
    isEdit && Boolean(CheckFieldLevelPermissions('DOB', isEdit))
      ? null
      : Yup.date()
          .required('Required field')
          .typeError('Required field')
          .when('hireDate', (value: any, schema: any) => {
            if (value != null) {
              return schema.test({
                test: (dateOfBirth: any) => {
                  // Ensure both dates are valid moments with the specified format
                  const hireDateMoment = moment(value, 'YYYY/MM/DD', true);
                  const dobMoment = moment(dateOfBirth, 'YYYY/MM/DD', true);

                  // If dates are not valid, return false (invalid dates will fail typeError)
                  if (!hireDateMoment.isValid() || !dobMoment.isValid()) {
                    return false;
                  }

                  const calculatedAge = hireDateMoment.diff(dobMoment, 'years', true);
                  return calculatedAge >= 18;
                },
                message: 'Should be at least 18 years when hired',
              });
            }
          }),

  // dateOfBirth:
  //   isEdit && Boolean(CheckFieldLevelPermissions('DOB', isEdit))
  //     ? null
  //     : Yup.date()
  //         .required('Required field')
  //         .typeError('Required field')
  //         .when('hireDate', (value: any, schema: any) => {
  //           if (value != null) {
  //             return schema.test({
  //               test: (dateOfBirth: any) => {
  //                 const formatUserSelectDate =
  //                   Boolean(value) && moment(dateOfBirth).format('YYYY/MM/DD').split('/').join(',');
  //                 const formatTodayDate =
  //                   Boolean(value) && moment(value).format('YYYY/MM/DD').split('/').join(',');
  //                 const calculatedAge = moment(String([formatTodayDate])).diff(
  //                   String([formatUserSelectDate]),
  //                   'years',
  //                   true
  //                 );
  //                 return calculatedAge >= 18;
  //               },
  //               message: 'Should be at least 18 years when hired',
  //             });
  //           }
  //         }),
});

export const OPTION_AWARDS_VALIDATION = (isEdit: boolean): any => ({
  participantId:
    isEdit && Boolean(CheckFieldLevelPermissions('EmployeeId', isEdit))
      ? null
      : commonValidation({ required: true }),
  // planTypeId: commonValidation({ required: true }),
  quantity: commonValidation({ required: true, digitsWithComma: true }),
  excercisePrice: commonValidation({ required: true, floatDigits: true, decimal: true }),
  grantPrice: commonValidation({ required: true, floatDigits: true, decimal: true }),
  marketValueAtGrant: commonValidation({ required: true, floatDigits: true, decimal: true }),
  grantId: commonValidation({ required: true }),
  grantDate: commonValidation({ required: true }),
  vestingStartDate: commonValidation({ required: true }),
  grantReason: commonValidation({ required: true, noBlank: true, doubleSpace: true }),
  remarks: commonValidation({ required: false, noBlank: true, doubleSpace: true }),
});

export const RSUAWARDS_VALIDATION = (isEdit: boolean): any => ({
  participantId:
    isEdit && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? commonValidation({ required: false })
      : commonValidation({ required: true }),
  quantity: commonValidation({ required: true, digitsWithComma: true }),
  grantPrice: commonValidation({ required: true, floatDigits: true, decimal: true }),
  marketValueAtGrant: commonValidation({ required: true, floatDigits: true, decimal: true }),
  grantId: commonValidation({ required: true }),
  grantDate: Yup.date().required('Required field').typeError('Required field'),
  vestingStartDate: Yup.date().required('Required field').typeError('Required field'),
  grantReason: commonValidation({ required: true, noBlank: true, doubleSpace: true }),
  remarks: commonValidation({ required: false, noBlank: true, doubleSpace: true }),
});

export const SYSTEM_USERS_VALIDATION = (isEdit?: boolean): any => ({
  email:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('EmployeeEmail', isEdit))
      ? null
      : GlobalValidation.Email,
  firstName:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : GlobalValidation.Name.matches(/^[a-z,0-9, @()-/*"':;_#-+]+$/i, 'Invalid First Name'),
  middleName:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : commonValidation({
          required: false,
          textLimit: 100,
          noBlank: true,
          doubleSpace: true,
        }).matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name'),
  lastName:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('FirstMiddleLastName', isEdit))
      ? null
      : GlobalValidation.Name.matches(/^[a-z,0-9, @()-/*"':;_#-+]+$/i, 'Invalid Last Name'),
  phone:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('PhoneNumber', isEdit))
      ? null
      : GlobalValidation.NonRequiredPhone.min(12, 'Must be 10 digits').when('phoneIsOtherCountry', {
          is: false,
          otherwise: (d: any) => GlobalValidation.NonRequiredPhone,
        }),
  employeeId:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('EmployeeId', isEdit))
      ? null
      : GlobalValidation.RequiredNoBlankAlphanumeric,
  countryCallingCodeId:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('PhoneNumber', isEdit))
      ? null
      : commonValidation({ required: false }),
  customerTitleId:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('JobTitle', isEdit))
      ? null
      : commonValidation({ required: false }),
  customerDepartmentId:
    (isEdit ?? false) && Boolean(CheckFieldLevelPermissions('Department', isEdit))
      ? null
      : commonValidation({ required: false }),
  customerRoleId: commonValidation({ required: true }),
});
export const CONFIRM_VALIDATION = Yup.object().shape({
  remarks: commonValidation({ required: true }),
});

export const APPROVAL_CONFIG_VALIDATION = Yup.object().shape({
  customerUserDtos: Yup.array().of(
    Yup.object().shape({
      vendorUserIds: Yup.array().min(1, 'Required field'),
    })
  ),
});

export const VESTING_TEMPLATES_VALIDATION = {
  name: GlobalValidation.Name,
  lengthInMonths: commonValidation({
    required: true,
    isNumber: true,
    max: 999,
    min: 1,
  }).test('Digits only', 'Must be whole number', (value: any) => /^\d+$/.test(value)),
  // planTypeCategoryId: commonValidation({ required: true }),
  vestingTypeId: commonValidation({ required: true }),
  roundingTypeId: commonValidation({ required: true }),
  summary: commonValidation({ required: false, max: 1000 }),
};

export const PLAN_MANAGEMENT_VALIDATION = {
  name: GlobalValidation.Name,
  planEffectiveDate: Yup.date().required('Required field').typeError('Required field'),
  planTypeCategoryIds: Yup.array().min(1, 'Required field'),
  numberOfSharesAuthorized: GlobalValidation.DigitWithComma,
  numberOfSharesUnAvailable: GlobalValidation.DigitWithComma.when(
    'numberOfSharesAuthorized',
    (value: any, schema: any) => {
      return schema.test({
        test: (approvedAmount: any) => {
          return (
            parseInt(approvedAmount?.toString()?.replaceAll(',', '') ?? '') <=
            parseInt(value?.toString()?.replaceAll(',', '') ?? '')
          );
        },
        message: 'Must not be greater than No. of Shares Authorized',
      });
    }
  ).typeError('Must be only digits'),
  // numberOfSharesAvailable: GlobalValidation.DigitWithComma,
  eligibleShareClass: GlobalValidation.RequiredNoBlank,
  description: GlobalValidation.Description,
  planNameList: Yup.array().nullable(),
  planTerm: Yup.number()
    .moreThan(0, 'Must be greater than 0')
    .integer('Must not be decimal')
    .typeError('Must be only digits')
    .required('Required field'),
  boardApprovalDate: Yup.date().required('Required field').typeError('Required field'),
  shareHolderApprovalDate: Yup.date().required('Required field').typeError('Required field'),
  evergreenPlan: commonValidation({ required: true }),
  evergreenCriteria: Yup.string()
    .nullable()
    .when(['evergreenPlan'], {
      is: (evergreenPlan: string) => {
        if (evergreenPlan === 'Yes') {
          return true;
        }
      },
      then: (schema) => schema.required('Required field').nullable(),
    }),
  planDocument: Yup.array()
    .of(
      Yup.mixed()
        .test('fileFormat', 'Unsupported file format', (value) => {
          if (value?.type) {
            return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
          } else {
            return (
              Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value?.file?.type)
            );
          }
        })
        .test('fileSize', 'File is too large', (value) => {
          if (value.fileSize <= FILE_SIZE || value?.file?.size <= FILE_SIZE) {
            return true;
          } else {
            return false;
          }
        })
    )
    .min(1, 'Required field')
    .when('planNameList', {
      is: (planNameList: any) => {
        const isNull = planNameList.length > 0;
        return !isNull;
      },
      otherwise: (d) =>
        Yup.array().of(
          Yup.mixed()
            .test('fileFormat', 'Unsupported file format', (value) => {
              if (value && value.type) {
                return SUPPORTED_FORMATS.some((item) => item.format === value.type);
              } else if (value && value.file && value.file.type) {
                return SUPPORTED_FORMATS.some((item) => item.format === value.file.type);
              } else {
                // If value.type doesn't exist, and neither does value.file.type, return true to pass the test
                return true;
              }
            })
            .test('fileSize', 'File is too large', (value) => {
              if (value.fileSize || (value?.file && value.file.size)) {
                if (value.fileSize <= FILE_SIZE || value?.file?.size <= FILE_SIZE) {
                  return true;
                } else {
                  return false;
                }
              }
              return true; // No fileSize or file.size, so validation passes
            })
        ),
    }),
};

export const GRANT_MANAGEMENT_VALIDATION = Yup.object().shape({
  grantName: GlobalValidation.Name,
  planTypeName: commonValidation({ required: true }),
  performanceRuleName: commonValidation({ required: false }).when(['planTypeName'], {
    is: (planTypeName: string) => {
      if (planTypeName === 'PSU') {
        return true;
      }
    },
    then: (schema: any) => schema.required('Required field').nullable(),
  }),
  vestingTemplateName: commonValidation({ required: true }),
  // documentNameList: Yup.array().nullable(),
  approvedDate: commonValidation({ required: true }),
  // grantDocument: Yup.array()
  //   .of(
  //     Yup.mixed()
  //       .test('fileFormat', 'Unsupported file format', (value) => {
  //         if (value.file != null) {
  //           return (
  //             Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.file.type)
  //           );
  //         } else {
  //           return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
  //         }
  //       })
  //       .test('fileSize', 'File is too large', (value) => {
  //         if (value.size <= FILE_SIZE || value.file.size <= FILE_SIZE) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       })
  //   )
  //   .when('documentNameList', {
  //     is: (documentNameList: any) => {
  //       const isNull = documentNameList.length > 0;
  //       return !isNull;
  //     },
  //     otherwise: (d) =>
  //       Yup.array().of(
  //         Yup.mixed()
  //           .test('fileFormat', 'Unsupported file format', (value) => {
  //             if (value.file != null) {
  //               return (
  //                 Boolean(value) &&
  //                 SUPPORTED_FORMATS.some((item) => item.format === value.file.type)
  //               );
  //             } else {
  //               return (
  //                 Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type)
  //               );
  //             }
  //           })
  //           .test('fileSize', 'File is too large', (value) => {
  //             if (value.size <= FILE_SIZE || value.file.size <= FILE_SIZE) {
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           })
  //       ),
  //   })
  //   .min(1, 'Required field'),
  agreementStatement: commonValidation({ required: true, noBlank: true }),
  agreementStatementStatusName: commonValidation({ required: true }),
  // termsAndConditionsDocument: Yup.array().of(
  //   Yup.mixed()
  //     .test('fileFormat', 'Unsupported file format', (value) => {
  //       if (value?.file != null) {
  //         return (
  //           Boolean(value) &&
  //           PDF_SUPPORTED_FORMATS.some((item) => item?.format === value?.file?.type)
  //         );
  //       } else {
  //         return (
  //           Boolean(value) && PDF_SUPPORTED_FORMATS.some((item) => item?.format === value?.type)
  //         );
  //       }
  //     })
  //     .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
  // ),
  // termsAndConditionsDocumentContent: Yup.string()
  //   .required('Either field must have value')
  //   .nullable()
  //   .when(['termsAndConditionsDocument', 'termsAndConditionsDocumentName'], {
  //     is: (document: any, termsAndConditionsDocumentName: any) => {
  //       if (isEmpty(document)) {
  //         if (termsAndConditionsDocumentName[0]?.file?.name === null) return true;
  //         else if (isEmpty(document) && isEmpty(termsAndConditionsDocumentName)) return true;
  //       } else {
  //         return false;
  //       }
  //     },
  //     otherwise: (d: any) => Yup.string().nullable(),
  //   }),
});

export const DESIGN_VALIDATION = Yup.object().shape({
  checkbox: Yup.boolean().required('Required field'),
  radioBox: Yup.boolean().required('Required field'),
  name1: Yup.string().required('Required field'),
  name2: Yup.string().required('Required field'),
  name3: Yup.string().required('Required field'),
});

export const HEADQUARTER_VALIDATION = {
  addressLine1: GlobalValidation.AddressLine1,
  addressLine2: GlobalValidation.AddressLine2,
  countryId: commonValidation({ required: true }),
  city: Yup.string()
    .required('Required field')
    .matches(/^[a-zA-Z ]*$/, `Invalid City Name`)
    .matches(/^\s*\S[\s\S]*$/, `Must not contain blank spaces`)
    .nullable(),
  zipCode: GlobalValidation.ZipCode.when('zipCodeErrorMessage', (value: any, schema: any) => {
    if (value != null && value != undefined) {
      return schema.test(
        'zipCodeValidation', // Name for the test
        value, // Custom error message
        (val: any) => val === true // Test function
      );
    }
    return schema;
  }),
};
export const CONTACTS_VALIDATION = {
  name: GlobalValidation.Name,
  email: GlobalValidation.Email,
  countryCallingCodeId: commonValidation({ required: true }),
  transferAgentId: commonValidation({
    required: false,
    noBlank: true,
  }).when('isTransferAgent', {
    is: false,
    otherwise: (d: any) => GlobalValidation.RequiredNoBlank,
  }),
  phone: GlobalValidation.Phone.min(12, 'Must be 10 digits').when('phoneIsOtherCountry', {
    is: false,
    otherwise: (d: any) => GlobalValidation.Phone,
  }),
};

export const UDF_VALIDATION = Yup.object().shape({
  fieldName: commonValidation({ required: true, noBlank: true, textLimit: 35 }),
  fieldType: GlobalValidation.RequiredNoBlank,
  isMandatory: commonValidation({ required: true }),
  status: commonValidation({ required: true }),
  orderNumber: commonValidation({
    isNumber: true,
    required: true,
    min: 1,
    max: 9999999,
  }),
  moduleId: commonValidation({ required: true }),
  description: commonValidation({ required: false, textLimit: 500 }),
});

export const TRADE_PRE_CLEARANCE_VALIDATION = Yup.object().shape({
  optionType: commonValidation({ required: true }),
  participantIds: Yup.array()
    .min(1, 'Required Field')
    .when(['isIndividual', 'selectAll', 'isGroup'], {
      is: (isIndividual: boolean, selectAll: boolean, isGroup: boolean) => {
        if (isIndividual) {
          if (!selectAll) return true;
        } else if (isGroup) return false;
        // else return false;
      },
      otherwise: (d: any) => Yup.array().nullable(),
    }),
  tradePreClearanceGroups: Yup.array()
    .of(
      Yup.object().shape({
        departmentId: commonValidation({ required: true }).when('groupData', {
          is: false,
          otherwise: (d: any) => commonValidation({ required: false }).nullable(),
        }),
        employeeTypeId: commonValidation({ required: true }).when('groupData', {
          is: false,
          otherwise: (d: any) => commonValidation({ required: false }).nullable(),
        }),
        jobTitleIds: Yup.array()
          .min(1, 'Required Field')
          .when('groupData', {
            is: false,
            otherwise: (d: any) => Yup.array().nullable(),
          }),
      })
    )
    .when('isGroup', {
      is: true,
      otherwise: (d: any) => Yup.array().nullable(),
    }),
});

export const TRADING_BLACKOUT_CONFIG_VALIDATION = Yup.object().shape({
  blackoutTitle: commonValidation({ required: true, noBlank: true, doubleSpace: true }).max(
    1000,
    'Message exceed 1000 character limit.'
  ),
  message: commonValidation({ required: true }).max(1000, 'Message exceed 1000 character limit.'),
  startDate: Yup.date().typeError('Required Field'),
  endDate: Yup.date().typeError('Required Field'),
  blackoutEligibilityId: commonValidation({ required: true }),
  blackoutGroups: Yup.array()
    .of(
      Yup.object().shape({
        customerDepartmentId: commonValidation({ required: true }).when('groupData', {
          is: false,
          otherwise: (d: any) => commonValidation({ required: false }).nullable(),
        }),
        employeeTypeId: commonValidation({ required: true }).when('groupData', {
          is: false,
          otherwise: (d: any) => commonValidation({ required: false }).nullable(),
        }),
        blackOutJobTitles: Yup.array()
          .min(1, 'Required Field')
          .when('groupData', {
            is: false,
            otherwise: (d: any) => Yup.array().nullable(),
          }),
      })
    )
    .when('blackoutEligibilityId', {
      is: '2',
      otherwise: (d: any) => Yup.array().nullable(),
    }),
});

export const TERMS_AND_CONDITIONS_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  pageId: commonValidation({ required: true }),
  isActive: commonValidation({ required: true }),
  agreementStatement: commonValidation({ required: true, noBlank: true }),
  document: Yup.array().of(
    Yup.mixed()
      .test('fileFormat', 'Unsupported file format', (value) => {
        if (value?.file != null) {
          return (
            Boolean(value) &&
            PDF_SUPPORTED_FORMATS.some((item) => item?.format === value?.file?.type)
          );
        } else {
          return (
            Boolean(value) && PDF_SUPPORTED_FORMATS.some((item) => item?.format === value?.type)
          );
        }
      })
      .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
  ),
  // .when('name', {
  //   is: (documentContent: any) => {
  //     // const isNull = documentContent.length > 0;
  //     // return !isNull;
  //   },
  //   // then: Yup.array().min(1, 'Required Field'),
  //   otherwise: (d) =>
  //     Yup.array().of(
  //       Yup.mixed()
  //         .test('fileFormat', 'Unsupported file format', (value) => {
  //           if (value.file) {
  //             return (
  //               value && PDF_SUPPORTED_FORMATS.some((item) => item.format === value.file.type)
  //             );
  //           } else {
  //             return value && PDF_SUPPORTED_FORMATS.some((item) => item.format === value.type);
  //           }
  //         })
  //         .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
  //     ),
  // })
  // .required('Required field'),
  documentContent: Yup.string()
    .required('Either field must have value')
    .nullable()
    .when(['document', 'documentList'], {
      is: (document: any, documentList: any) => {
        if (isEmpty(document)) {
          if (documentList[0]?.file?.name === null) return true;
          else if (isEmpty(document) && isEmpty(documentList)) return true;
        } else {
          return false;
        }
      },
      otherwise: (d: any) => Yup.string().nullable(),

      // if (value) {
      // return schema.test({
      //   test: (dateOfBirth: any) => {
      //     let formatUserSelectDate =
      //       value && moment(dateOfBirth).format('YYYY/MM/DD').split('/').join(',');
      //     let formatTodayDate = value && moment(value).format('YYYY/MM/DD').split('/').join(',');
      //     let calculatedAge = moment([formatTodayDate]).diff(
      //       [formatUserSelectDate],
      //       'years',
      //       true
      //     );
      //     return calculatedAge >= 18;
      //   },
      //   message: 'Should be at least 18 years when hired',
      // }
    }),
});

export const CHANGE_PASSWORD_VALIDATION = Yup.object().shape({
  currentPassword: Yup.string().required('Required field'),
  newPassword: Yup.string().required('Required field').matches(passwordRegExp, 'Invalid Password'),
  repeatPassword: Yup.string()
    .required('Required field')
    .test('match', 'Password do not match', function (repeatPassword) {
      return repeatPassword === this.parent.newPassword;
    }),
});

export const RETIREMENT_ELIGIBILITY_VALIDATION = {
  eligibilityCriteriaId: Yup.string().required('Required field'),
  ageMonth: Yup.string()
    .matches(/^[0-9][0-9]*$/, {
      message: 'Must be whole number',
      excludeEmptyString: true,
    })
    .nullable(),
  ageYear: Yup.string()
    .matches(/^[0-9][0-9]*$/, {
      message: 'Must be whole number',
      excludeEmptyString: true,
    })
    .nullable()
    .when(['eligibilityOption', 'ageMonth'], {
      is: (eligibility: string, ages: any) =>
        (eligibility === 'Age' || eligibility === 'Both') && ages == null,
      then: Yup.string()
        .matches(/^[0-9][0-9]*$/, {
          message: 'Must be whole number',
          excludeEmptyString: true,
        })
        .required('Either field must have value')
        .nullable(),
      otherwise: (d: any) =>
        Yup.string()
          .matches(/^[0-9][0-9]*$/, {
            message: 'Must be whole number',
            excludeEmptyString: true,
          })
          .nullable(),
    }),
  tenureCompletedMonth: Yup.string()
    .matches(/^[0-9][0-9]*$/, {
      message: 'Must be whole number',
      excludeEmptyString: true,
    })
    .nullable(),
  tenureCompletedYear: Yup.string()
    .matches(/^[0-9][0-9]*$/, {
      message: 'Must be whole number',
      excludeEmptyString: true,
    })
    .nullable()
    .when(['eligibilityOption', 'tenureCompletedMonth'], {
      is: (eligibility: string, tenure: any) =>
        (eligibility === 'Tenure' || eligibility === 'Both') && tenure == null,
      then: Yup.string()
        .matches(/^[0-9][0-9]*$/, {
          message: 'Must be whole number',
          excludeEmptyString: true,
        })
        .required('Either field must have value')
        .nullable(),
      otherwise: (d: any) =>
        Yup.string()
          .matches(/^[0-9][0-9]*$/, {
            message: 'Must be whole number',
            excludeEmptyString: true,
          })
          .nullable(),
    }),

  // Yup.number().when('eligibilityOption', {
  //   is: (value: any) => value === 'Age' || value === 'Both',
  //   then: Yup.number().min(1, 'Minimum month 1.').max(11, 'Maximum month 11.').nullable(),
  //   otherwise: (d: any) => Yup.number().nullable(),
  // }),
  // tenureCompletedYear: Yup.number().when('eligibilityOption', {
  //   is: (value: any) => value === 'Tenure' || value === 'Both',
  //   then: Yup.number()
  //     .required('Required')
  //     .min(1, 'Minimum tenure 1.')
  //     .max(50, 'Maximum tenure 50.')
  //     .nullable(),
  //   otherwise: (d: any) => Yup.number().nullable(),
  // }),
  // tenureCompletedMonth: Yup.number().when('eligibilityOption', {
  //   is: (value: any) => value === 'Tenure' || value === 'Both',
  //   then: Yup.number()
  //     .min(1, 'Minimum tenure month 1.')
  //     .max(11, 'Maximum tenure month 11.')
  //     .nullable(),
  //   otherwise: (d: any) => Yup.number().nullable(),
  // }),
};
export const PARTICIPANT_BROADCAST_MESSAGE_VALIDATION = Yup.object().shape({
  title: GlobalValidation.Name,
  messageTemplateId: commonValidation({ required: true }),
  message: Yup.string()
    .nullable()
    .when(['messageTemplateId'], {
      is: (messageTemplateId: string) => {
        if (messageTemplateId === '11') {
          return true;
        }
      },
      then: (schema) => schema.required('Required field').nullable(),
    }),
  startDate: GlobalValidation.Date,
  endDate: Yup.date()
    .required('Required field')
    .typeError('Required field')
    .test('is-greater', 'End date must be after start date', function (value) {
      const { startDate } = this.parent;
      return startDate == null || value == null || new Date(value) >= new Date(startDate);
    }),
});
export const TERMINATE_PARTICIPANT_VALIDATION = Yup.object().shape({
  terminationTypeId: GlobalValidation.RequiredNoBlank,
  awardStatusId: commonValidation({ required: true }),
  terminationDate: GlobalValidation.RequiredNoBlank,
  awardEffectiveDate: GlobalValidation.RequiredNoBlank.when(['awardStatus'], {
    is: (awardStatus: string) => {
      if (awardStatus !== 'Status Quo') {
        return true;
      }
    },
    otherwise: (d: any) => Yup.string().nullable(),
  }),
  remarks: commonValidation({ required: true, noBlank: true, doubleSpace: true }),
  awardDetails: Yup.array().of(
    Yup.object().shape({
      outstandingUnits: Yup.string().nullable(),
      awardEffectiveDate: commonValidation({ required: true })
        .when(['awardStatusName', 'awardStatus'], {
          is: (awardStatusName: string, awardStatus: string) => {
            if (awardStatusName === 'Status Quo') {
              if (awardStatus !== 'Status Quo') {
                return true;
              } else {
                return false;
              }
            } else if (awardStatusName !== 'Status Quo') {
              if (awardStatus !== 'Status Quo' && awardStatusName !== awardStatus) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          },
          otherwise: (d: any) => Yup.string().nullable(),
        })
        .test({
          test: (value: any) => {
            return value !== 'Invalid date';
          },
          message: 'Required field',
        }),
      applicableUnits: commonValidation({ required: true, digits: true })
        .matches(greaterThenZero, 'Must be greater then 0')
        .nullable()
        .when('outstandingUnits', (value: any, schema: any) => {
          return schema.test({
            test: (approvedAmount: any) => {
              return parseInt(approvedAmount) <= parseInt(value);
              // parseInt(approvedAmount?.toString()?.replaceAll(',', '') || '') <=
              // parseInt(value?.toString()?.replaceAll(',', '') || '')
            },
            message: 'Must not be greater than Outstanding Units',
          });
        })
        .typeError('Must be only digits'),
    })
  ),
});

export const SECURITY_QUESTIONS_VALIDATION = Yup.object().shape({
  securityQuestion1Id: commonValidation({ required: true }).nullable(),
  securityQuestion2Id: commonValidation({ required: true }).nullable(),
  securityQuestion3Id: commonValidation({ required: true }).nullable(),
  securityAnswer1: GlobalValidation.RequiredNoBlank,
  securityAnswer2: GlobalValidation.RequiredNoBlank,
  securityAnswer3: GlobalValidation.RequiredNoBlank,
});

export const ADDITIONAL_ADDRESS_VALIDATION = Yup.object().shape({
  companyAddressList: Yup.array()
    .of(
      Yup.object().shape({
        countryId: commonValidation({ required: true }),
        addressLine1: GlobalValidation.AddressLine1.nullable(),
        addressLine2: GlobalValidation.AddressLine2.nullable(),
        taxId: commonValidation({
          required: true,
          max: 9,
          digits: true,
        })
          .min(9, 'Must be 9 digits')
          .required('Required field'),
        stateText: Yup.string()
          .required('Required field')
          .matches(/^[a-zA-Z ]*$/, `Invalid State Name`)
          .when('isOtherCountry', {
            is: true,
            otherwise: (d: any) => Yup.string().nullable(),
          }),
        stateId: Yup.string()
          .required('Required field')
          .when('isOtherCountry', {
            is: false,
            otherwise: (d: any) => Yup.string().nullable(),
          }),
        cityText: Yup.string()
          .required('Required field')
          .matches(/^[a-zA-Z ]*$/, `Invalid City Name`)
          .nullable(),
        zipCode: commonValidation({
          required: true,
          digits: true,
          noBlank: true,
          max: 5,
          min: 5,
          minValue: 5,
        })
          .when('zipCodeErrorMessage', (value: any, schema: any) => {
            if (value != null && value != undefined) {
              return schema.test(
                'zipCodeValidation', // Name for the test
                value, // Custom error message
                (val: any) => val === true // Test function
              );
            }
            return schema;
          })
          .when('isOtherCountry', {
            is: false,
            otherwise: (d: any) =>
              commonValidation({
                required: true,
                digits: true,
                noBlank: true,
              }),
          }),
        stateOfIncorporationId: commonValidation({ required: true })
          .nullable()
          .when('isOtherCountryOfIncorporation', {
            is: false,
            otherwise: (d: any) => commonValidation({ required: false }),
          }),
      })
    )
    .nullable(),
});

export const TAX_PERIOD_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name,
  effectiveFrom: GlobalValidation.Date,
  effectiveTill: Yup.date()
    .required('Required field')
    .typeError('Required field')
    .test('is-greater', 'End date must be after start date', function (value) {
      const { effectiveFrom } = this.parent;
      return effectiveFrom == null || value == null || new Date(value) >= new Date(effectiveFrom);
    }),
});
// export const TAX_OVERRIDE_VALIDATION = {
//   name: GlobalValidation.Name,
//   taxBrackets: Yup.array().of(
//     Yup.object().shape({
//       taxRate: commonValidation({ required: true, floatDigits: true, decimal: true }),
//       lowerLimit: commonValidation({
//         required: false,
//         digitsWithComma: true,
//         noBlank: true,
//       }),
//       upperLimit: commonValidation({
//         required: false,
//         digitsWithComma: true,
//         noBlank: true,
//       }),
//     })
//   ),
// };
declare module 'Yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(path?: any, message?: any, mapper?: (a: T) => T): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'unique', function (propertyPath, message) {
  return this.test('unique', '', function (list) {
    const errors = [] as any;

    list?.forEach((item, index) => {
      const propertyValue = _.get(item, propertyPath);

      if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          })
        );
      }
    });

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});

declare module 'Yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(path?: any, message?: any, mapper?: (a: T) => T): ArraySchema<T>;
  }
}

// Yup.addMethod(Yup.array, 'unique', function (message, path) {
//   return this.test('unique', function (lists) {
//     const uniqArray = _.uniqBy(lists, path),
//       mapper = (x: any) => _.get(x, path),
//       set = [new Set(lists?.map(mapper))];
//     if (uniqArray.length !== lists?.length) {
//       const idx = _.findIndex(lists, (list, i) => mapper(list) !== set[i]);
//       if (idx !== -1) {
//         return this.createError({
//           path: `${this.path}[${idx}].${path}`,
//           message,
//         });
//       }
//     }
//     return true;
//   });
// });

// Yup.addMethod(Yup.array, 'unique', function (message, path) {
//   return this.test('unique', message, function (list) {
//     const duplicates = list
//       ?.map((r) => r[path])
//       ?.filter((value, index, self) => self.indexOf(value) !== index);
//     if (duplicates?.length == 0) return true;

//     // find first duplicated.
//     list?.forEach((row, index) => {
//       if (_.includes(duplicates, row[path])) {
//         throw this.createError({
//           path: `${this.path}[${index}].${path}`,
//           message,
//         });
//       }
//     });

//     return true;
//   });
// });

Yup.addMethod(Yup.array, 'unique', function (propertyPath, message) {
  return this.test('unique', '', function (list) {
    const errors = [] as any;

    list?.forEach((item, index) => {
      const propertyValue = _.get(item, propertyPath);

      if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          })
        );
      }
    });

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});

export const TAX_SETUP_VALIDATION = (
  validation?: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | any,
  applicableName?: string
): any => {
  return {
    name: GlobalValidation.Name,
    jurisdictionLevelId: commonValidation({ required: true }),
    taxPeriodId: commonValidation({ required: true }),
    equityCompensationLevelId: commonValidation({ required: true }),
    countryId: (validation?.requiresCountry as boolean)
      ? validation?.establishedCountry === 'US'
        ? null
        : commonValidation({ required: true })
      : null,
    stateId: Boolean(validation?.requiresState) && commonValidation({ required: true }),
    cityId: Boolean(validation?.requiresCity) && commonValidation({ required: true }),
    participantId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' || applicableName === 'Participant') &&
      commonValidation({ required: true }),
    planId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' ||
        applicableName === 'Plan' ||
        applicableName === 'Grant') &&
      commonValidation({ required: true }),
    grantId:
      !isEmpty(applicableName) &&
      (applicableName === 'Vesting Tranche' || applicableName === 'Grant') &&
      commonValidation({ required: true }),
    awardId:
      !isEmpty(applicableName) &&
      applicableName === 'Vesting Tranche' &&
      commonValidation({ required: true }),

    transactionId:
      !isEmpty(applicableName) &&
      applicableName === 'Vesting Tranche' &&
      commonValidation({ required: true }),
    userDefinedCriteria:
      Boolean(validation?.allowUserDefinedCriteria) &&
      Yup.object().shape({
        // logicalOperatorType: commonValidation({ required: true }),
        Conditions: Yup.array().of(
          Yup.object().shape({
            SubCriteria: Yup.object().shape({
              // logicalOperatorType: commonValidation({ required: true }),
              Conditions: Yup.array().of(
                Yup.object().shape({
                  // Field: commonValidation({ required: true }),
                  EqualityOperatorType: commonValidation({ required: true }).when('Field', {
                    is: (value: any) => !isEmpty(value),
                    otherwise: (d: any) =>
                      commonValidation({
                        required: false,
                      }),
                  }),
                  // Name: commonValidation({ required: true }).when('Field', {
                  //   is: (value: any) => !isEmpty(value),
                  //   otherwise: (d: any) =>
                  //     commonValidation({
                  //       required: false,
                  //     }),
                  // }),
                  Value: commonValidation({ required: true }).when('Field', {
                    is: (value: any) => !isEmpty(value),
                    otherwise: (d: any) =>
                      commonValidation({
                        required: false,
                      }),
                  }),
                })
              ),
            }),
          })
        ),
      }),
    taxBrackets: Yup.array()
      .of(
        Yup.object().shape({
          taxRate: Yup.string()
            .matches(/^[0-9.,]*$/, 'Must be only digits')
            .matches(/^\d{1,3}(?:,\d{3})*(?:\.\d{1,4})?$/, 'Must be 4 decimal place only')
            .required('Required Field'),
          lowerLimit: commonValidation({
            required: true,
            digitsWithComma: true,
            noBlank: true,
          }),
          upperLimit: commonValidation({
            required: false,
            digitsWithComma: true,
            noBlank: true,
          }),
          // .when('lowerLimit', (value: any, schema: any) => {
          //   return (
          //     value &&
          //     schema.test({
          //       test: (approvedAmount: any) => {
          //         return (
          //           parseInt(approvedAmount?.toString()?.replaceAll(',', '') ?? '') >
          //           parseInt(value?.toString()?.replaceAll(',', '') ?? '')
          //         );
          //       },
          //       message: 'Must be greater than Lower limits',
          //     })
          //   );
          // })
          // .typeError('Must be only digits'),
        })
      )
      .unique('lowerLimit', 'Duplicate lower bracket limit'),
  };
};
export const DYNAMIC_REPORT_RENAME_VALIDATION = Yup.object().shape({
  reportName: GlobalValidation.Name,
});
export const RELEASE_RENAME_VALIDATION = Yup.object().shape({
  releaseName: GlobalValidation.Name,
});
export const TRANSFER_ALLOCATE_RENAME_VALIDATION = Yup.object().shape({
  transferAllocateName: GlobalValidation.Name,
});

export const TAX_CALCULATE_VALIDATION = (): any => {
  return {
    taxDetails: Yup.array().of(
      Yup.object().shape({
        taxName: GlobalValidation.Name,
        taxRate: commonValidation({
          required: true,
          noBlank: true,
          floatDigits: true,
          decimal: true,
        }),
        taxAmount: commonValidation({
          required: true,
          noBlank: true,
          floatDigits: true,
          decimal: true,
        }),
      })
    ),
  };
};

export const PERFORMANCE_RULE_VALIDATION = Yup.object().shape({
  ruleName: GlobalValidation.Name,
  ruleTypeId: commonValidation({
    required: true,
    noBlank: true,
  }),
  evaluationPeriodStartDate: GlobalValidation.RequiredNoBlank,
  evaluationPeriodEndDate: GlobalValidation.RequiredNoBlank,
  minimumPercentage: commonValidation({
    required: true,
    noBlank: true,
    floatDigits: true,
    decimal: true,
  }),
  maximumPercentage: commonValidation({
    required: true,
    noBlank: true,
    floatDigits: true,
    decimal: true,
  }).when('minimumPercentage', (minimumPercentage: any, schema: any) => {
    return schema.test({
      test: function (maximumPercentage: any) {
        const minimumPercentageNumber = parseInt(
          minimumPercentage?.toString()?.replaceAll(',', '') ?? ''
        );
        const maximumPercentageNumber = parseInt(
          maximumPercentage?.toString()?.replaceAll(',', '') ?? ''
        );
        return (
          maximumPercentageNumber >= minimumPercentageNumber ||
          this.createError({ message: 'Must be greater than minimum%' })
        );
      },
    });
  }),

  ruleDescription: GlobalValidation.RequiredNoBlank,
  ruleSummary: GlobalValidation.RequiredNoBlank,
  estimatePerformanceTrackings: Yup.array().of(
    Yup.object().shape({
      trackingDate: commonValidation({ required: true, noBlank: true }),
      multiplierPercentage: commonValidation({
        required: true,
        noBlank: true,
        floatDigits: true,
        decimal: true,
      }).test(
        'is-greater-than-minimum',
        'Must be greater than minimum% and less than maximum%',
        function (multiplierPercentage: any) {
          const { minValue, maxValue } = this.parent; // Accessing parent values
          const minimumPercentageNumber = parseFloat(
            minValue?.toString()?.replaceAll(',', '') ?? ''
          );
          const maximumPercentageNumber = parseFloat(
            maxValue?.toString()?.replaceAll(',', '') ?? ''
          );
          const multiplierPercentageNumber = parseFloat(
            multiplierPercentage?.toString()?.replaceAll(',', '') ?? ''
          );
          return (
            multiplierPercentageNumber >= minimumPercentageNumber &&
            multiplierPercentageNumber <= maximumPercentageNumber
          );
        }
      ),

      comment: commonValidation({ required: false }),
    })
  ),
  performanceEvaluation: Yup.object()
    .shape({
      evaluationDate: commonValidation({ required: true, noBlank: true }).when(
        ['earnedMultipier'],
        {
          is: (earnedMultipier: string) => {
            if (earnedMultipier) {
              return true;
            }
          },
          otherwise: (d: any) => commonValidation({ required: false, noBlank: true }),
        }
      ),
      earnedMultipier: commonValidation({
        required: false,
        noBlank: true,
        floatDigits: true,
        decimal: true,
      }).test(
        'is-greater-than-minimum',
        'Must be greater than minimum% and less than maximum%',
        function (earnedMultipier: any, { parent }: any) {
          const { minValue, maxValue } = this.parent; // Accessing parent values
          const minimumPercentageNumber = parseFloat(
            minValue?.toString()?.replaceAll(',', '') ?? ''
          );
          const maximumPercentageNumber = parseFloat(
            maxValue?.toString()?.replaceAll(',', '') ?? ''
          );
          const multiplierPercentageNumber = parseFloat(
            earnedMultipier?.toString()?.replaceAll(',', '') ?? ''
          );

          if (parent.earnedMultipier !== undefined && earnedMultipier !== undefined) {
            return (
              multiplierPercentageNumber >= minimumPercentageNumber &&
              multiplierPercentageNumber <= maximumPercentageNumber
            );
          }
          return true;
        }
      ),
    })
    .nullable(),
});

export const PREVIEW_VESTING_VALIDATION = Yup.object().shape({
  vestStartDate: commonValidation({
    required: true,
    noBlank: true,
  }),
  awardShares: commonValidation({
    required: true,
    noBlank: true,
    negativeDigits: true,
  }),
});
export const PLAN_DOCUMENT_VALIDATION = Yup.object().shape({
  grantDocumentName: commonValidation({
    required: true,
    noBlank: true,
  }),
  document: Yup.array().when('grantTermsAndConditionText', {
    is: undefined,
    then: Yup.array()
      .of(
        Yup.mixed()
          .test('fileFormat', 'Unsupported file format', (value) => {
            if (value) {
              return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
            } else {
              return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
            }
          })
          .test('fileSize', 'File is too large', (value) => {
            if (value.fileSize <= FILE_SIZE || value.file.size <= FILE_SIZE) {
              return true;
            } else {
              return false;
            }
          })
      )
      .min(1, 'Required field'),

    otherwise: Yup.array().of(
      Yup.mixed()
        .test('fileFormat', 'Unsupported file format', (value) => {
          if (value) {
            return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
          } else {
            return Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type);
          }
        })
        .test('fileSize', 'File is too large', (value) => {
          if (value.fileSize <= FILE_SIZE || value.file.size <= FILE_SIZE) {
            return true;
          } else {
            return false;
          }
        })
    ),
  }),

  grantTermsAndConditionText: Yup.string().when('text', {
    is: true,
    then: Yup.string().required('Required field'),
    otherwise: Yup.string(),
  }),
});
