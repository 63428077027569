import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Tag from '../../components/lozenge/tags';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';

export const SystemUserHeader = (
  actions: any,
  handleShow: (id: string, event: any) => void,
  hasMousePosition: boolean,
  resetMousePosition: () => void
): any => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (!(event.target as HTMLElement).className.includes('roles-popovers')) {
        resetMousePosition();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetMousePosition]);

  const getActions = (rowData: Record<string, any>): any => {
    let newActions = [];
    if (rowData.status === 'Pending') {
      newActions = actions.filter(
        (item: any) =>
          item.action !== 'View Comments' &&
          item.action !== 'Resend Mail' &&
          item?.action?.props?.children !== 'Deactivate'
      );
    } else if (rowData.status === 'Approved') {
      newActions = actions.filter(
        (item: any) =>
          ['Edit', 'View', 'Deactivate'].includes(item.action) ||
          ['Deactivate'].includes(item?.action?.props?.children)
      );
      if (rowData?.reSendEmail) {
        newActions = actions.filter(
          (item: any) =>
            ['Edit', 'View', 'Deactivate', 'Resend Mail'].includes(item.action) ||
            ['Deactivate'].includes(item?.action?.props?.children)
        );
      }
    } else if (rowData.status === 'Rejected') {
      newActions = actions.filter((item: any) => ['View Comments', 'View'].includes(item.action));
    } else {
      newActions = actions;
    }

    // if (rowData.isDefaultAdmin === true) {
    //   newActions = actions.filter((item: any) => ['View'].includes(item.action));
    // }
    if (!isEmpty(rowData.isEnabled) || rowData.isEnabled === false) {
      newActions = actions.filter((item: any) =>
        ['View Comments', 'View', 'Activate'].includes(item.action)
      );
    }
    return newActions;
  };

  return [
    {
      Header: 'Employee Details',
      accessor: 'fullName',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {(row.original.fullName as boolean) ? row.original.fullName : '-'}
            </div>
            <div className='cellSubtitle'>
              {(row.original.email as boolean) ? row.original.email : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Department',
      accessor: 'customerDepartment',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {(row.original.customerDepartment as boolean) ? row.original.customerDepartment : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Job Title',
      accessor: 'customerTitle',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {(row.original.customerTitle as boolean) ? row.original.customerTitle : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'System Role',
      accessor: 'customerRole',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <PopoverModelWrapper className='roles-popovers'>
            <Heading
              variant='body-s'
              title={(row.original.customerRole as boolean) ? row.original.customerRole : '-'}
              onClick={(e: any) => {
                setTitle(
                  `${String(
                    (row.original.customerRole as boolean) ? row.original.customerRole : ''
                  )}-${String(row.id ?? '')}`
                );
                handleShow(row.original.customerRoleId, e);
              }}
              className={`title roles-popovers ${
                hasMousePosition &&
                title === `${String(row.original.customerRole ?? '')}-${String(row.id ?? '')}`
                  ? 'active'
                  : ''
              }`}
            />
          </PopoverModelWrapper>
        );
      },
    },
    {
      Header: 'User Status',
      accessor: 'isEnabled',
      width: 200,
      Cell: ({ row }: any) => (
        <div className='cellTitle'>
          {(row.original.isEnabled as boolean) ? (
            <Tag variant={'info'} title={'Active' ?? '-'} />
          ) : row.original.isEnabled === false ? (
            <Tag variant={'error'} title={'Deactivated' ?? '-'} />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'statusId',
      width: 200,
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.status === 'Approved'
              ? 'success'
              : row.original.status === 'Rejected'
              ? 'error'
              : 'info'
          }
          title={(row.original.status as boolean) ? row.original.status : '-'}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.customerUserId}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
