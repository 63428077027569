/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto } from '../models/Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto';
import type { Wealthlane_AwardService_Dtos_Plan_CreatePlanDto } from '../models/Wealthlane_AwardService_Dtos_Plan_CreatePlanDto';
import type { Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_Plan_GetPlanGrantDocumentBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_Plan_GetPlanGrantDocumentBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanBlobDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanBlobDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanDocumentTypeDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanDocumentTypeDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanGrantDocumentListDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanGrantDocumentListDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanListDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanListDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanStatusDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanStatusDto';
import type { Wealthlane_AwardService_Dtos_Plan_UpdatePlanDto } from '../models/Wealthlane_AwardService_Dtos_Plan_UpdatePlanDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlanService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanPlan(
requestBody?: Wealthlane_AwardService_Dtos_Plan_CreatePlanDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/plan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPlanPlan(
requestBody?: Wealthlane_AwardService_Dtos_Plan_UpdatePlanDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/plan/plan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/{id}/plan-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanByIdForUpdate(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/{id}/plan-by-id-for-update',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanDownloadPlanDocument(
requestBody?: Wealthlane_AwardService_Dtos_Plan_GetPlanBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/download-plan-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppPlanPlan(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/plan/plan/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param input 
     * @param participantId 
     * @returns Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlansForTax(
input?: string,
participantId?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Grant_PlanNameTaxDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plans-for-tax',
            query: {
                'Input': input,
                'ParticipantId': participantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanByIdForTax(
planId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-by-id-for-tax/{PlanId}',
            path: {
                'PlanId': planId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @param grantDocumentType 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanGrantDocumentListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanGrantDocumentList(
planId: string,
grantDocumentType?: number,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanGrantDocumentListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-grant-document-list/{PlanId}',
            path: {
                'PlanId': planId,
            },
            query: {
                'GrantDocumentType': grantDocumentType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanDownloadPlanGrantDocument(
requestBody?: Wealthlane_AwardService_Dtos_Plan_GetPlanGrantDocumentBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/download-plan-grant-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDocumentTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanPlanDocumentTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanDocumentTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan/plan-document-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param planId 
     * @param documentName 
     * @param documentType 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanCheckIfExistPlanGrantDocumentName(
planId: string,
documentName?: string,
documentType?: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan/check-if-exist-plan-grant-document-name/{planId}',
            path: {
                'planId': planId,
            },
            query: {
                'documentName': documentName,
                'documentType': documentType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
