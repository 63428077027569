/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_ApproveCustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_ApproveCustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerUserApprovalService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserApprovalApproveCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_ApproveCustomerUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-approval/approve-customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserApprovalRejectCustomerUser(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_ApproveCustomerUserDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-approval/reject-customer-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param statuses 
     * @param isEnabled 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserApprovalCustomerUserList(
statuses?: Array<number>,
isEnabled?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-approval/customer-user-list',
            query: {
                'Statuses': statuses,
                'IsEnabled': isEnabled,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserApprovalCustomerUserById(
customerUserId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-approval/customer-user-by-id/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserApprovalCustomerUserStatusList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-approval/customer-user-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerUserId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserApprovalRemarks(
customerUserId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-approval/remarks/{customerUserId}',
            path: {
                'customerUserId': customerUserId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
