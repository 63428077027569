/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto';
import type { Wealthlane_AwardService_Dtos_Common_VestingCanccellationCalculationTestResult } from '../models/Wealthlane_AwardService_Dtos_Common_VestingCanccellationCalculationTestResult';
import type { Wealthlane_AwardService_Dtos_OptionAward_ExportOptionAwardDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_ExportOptionAwardDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDataDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDataDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionAwardService {

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardGrants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/grants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardPlanTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/plan-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardVestingSchedule(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/vesting-schedule',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardEmployeeStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/employee-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAcceptances(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-acceptances',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardEmployeeIds(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/employee-ids',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardGrantDetails(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetGrantDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/grant-details',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param createdFromDate 
     * @param createdToDate 
     * @param employeeStatuses 
     * @param optionStatus 
     * @param optionAcceptance 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
createdFromDate?: string,
createdToDate?: string,
employeeStatuses?: Array<number>,
optionStatus?: number,
optionAcceptance?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'EmployeeStatuses': employeeStatuses,
                'OptionStatus': optionStatus,
                'OptionAcceptance': optionAcceptance,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardForUpdateById(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-for-update-by-id/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardNumbers(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-numbers/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fromdate 
     * @param todate 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardFileNamesByDate(
fromdate?: string,
todate?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/file-names-by-date',
            query: {
                'fromdate': fromdate,
                'todate': todate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardUpcomingVestingGrants(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/upcoming-vesting-grants',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardUpcomingGrantList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/upcoming-grant-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardAdminDashboardGrantView(
vestingDate?: string,
grantId?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/admin-dashboard-grant-view',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardAdminDashboardGrantList(
vestingDate?: string,
grantId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/admin-dashboard-grant-list',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardBulkImportStatus(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/bulk-import-status/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardValidateBulkOptionAwards(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/validate-bulk-option-awards',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param validateSessionId 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardImportBulkOptionAwards(
validateSessionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/import-bulk-option-awards/{validateSessionId}',
            path: {
                'validateSessionId': validateSessionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardOptionAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/option-awards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppOptionAwardOptionAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/option-award/option-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardCancelOptionAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/cancel-option-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardSendParticipantsSignUpLinks(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/send-participants-sign-up-links',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppOptionAwardOptionAward(
requestBody?: Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/option-award/option-award',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDataDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardBulkUpdateOptionAward(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDataDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/bulk-update-option-award',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardOptionAwardsBulkUpdate(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/option-awards-bulk-update',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardOverview(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-overview/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardVestingSchedule(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-vesting-schedule/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardDocuments(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-documents/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardUploadOptionAwardSample(
formData?: {
document?: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/upload-option-award-sample',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardDownloadOptionAwardSample(): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardSampleFileBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/download-option-award-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardGetExportOptionAward(
requestBody?: Wealthlane_AwardService_Dtos_OptionAward_ExportOptionAwardDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/get-export-option-award',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardExportAdminDashboardGrantList(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/export-admin-dashboard-grant-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardOptionAwardDetailByOptionAwardId(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award/option-award-detail-by-option-award-id/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Common_VestingCanccellationCalculationTestResult Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardOptionAwardCancellationTest(
formData?: {
AsOfDate?: string;
ResidualShareDate?: string;
ModificationTypeId?: number;
TargetOutStandingShares?: number;
TargetCancelledShares?: number;
VestingTranchFile?: Blob;
},
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_VestingCanccellationCalculationTestResult>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award/option-award-cancellation-test',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
