import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerUserService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';
import { type TableParamsType } from '../../types/common';

const useGetAllCustomerUserList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  status?: number[],
  isEnabled?: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const allCustomerUserList = useQuery(
    ['getAllCustomerUserList', status, isEnabled, filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUserList(
          status,
          isEnabled,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
    // {
    //   keepPreviousData: true,
    // }
  );
  return allCustomerUserList;
};

export default useGetAllCustomerUserList;
