import { type TableParamsType } from '../../types/common';
import { type UseQueryResult, useQuery } from 'react-query';
import {
  EmployeeTypeService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';

const useGetEmployeeTypesListWithPagination = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const EmployeeTypesList = useQuery(
    ['getEmployeeTypesListWithPagination', filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        EmployeeTypeService.getApiCustomerApiAppEmployeeTypeEmployeeTypesList(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return EmployeeTypesList;
};

export default useGetEmployeeTypesListWithPagination;
