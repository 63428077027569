import { type UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  NotificationService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-notification-services';

const useGetEmailNotificationList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    filter,
    sorting,
    skipCount,
    maxResultCount,
    name: title,
    fileName: notificationMedium,
    type: NotificationType,
  } = tableInfo;
  const emailNotificationList = useQuery(
    [
      'getEmailNotificationList',
      filter,
      sorting,
      skipCount,
      maxResultCount,
      title,
      notificationMedium,
      NotificationType,
    ],
    async () => {
      return await handleRequest(
        NotificationService.getApiNotificationApiAppNotificationEmailNotificationList(
          title,
          notificationMedium,
          NotificationType,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return emailNotificationList;
};

export default useGetEmailNotificationList;
