import { isEmpty } from 'lodash';
import { TableBodyCellColumnCenter } from '../style/table.styled';
import moment from 'moment';
import { DATE_FORMAT } from '../common';

export const EmailLogHeader = [
  {
    Header: 'Title',
    accessor: 'title',
    width: 200,
  },
  {
    Header: 'From',
    accessor: 'from',
    sortable: false,
    //   width: 500,
  },
  {
    Header: 'To',
    accessor: 'to',
    sortable: false,
  },
  {
    Header: 'Description',
    accessor: 'descriptionTitle',
    sortable: false,
  },
  // {
  //   Header: 'Message',
  //   accessor: 'message',
  // },
  {
    Header: 'Notification Medium',
    accessor: 'notificationMedium',
  },
  {
    Header: 'Notification Type',
    accessor: 'notificationType',
  },
  {
    Header: 'CreationTime',
    accessor: 'creationTime',
    Cell: ({ row }: any) => {
      return (
        <TableBodyCellColumnCenter>
          {!isEmpty(row.original.creationTime)
            ? moment(row.original.creationTime).format(DATE_FORMAT)
            : '-'}
        </TableBodyCellColumnCenter>
      );
    },
  },
];
