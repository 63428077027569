import { Form, Formik, type FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import CustomTextArea from '../../../components/text-area/textArea';
import { type ModalStatusType, type OptionType } from '../../../types/common';
import { toast } from 'react-toastify';
import { PlanService } from '../../../services/wealthlane-award-services';
import { ImagePreview, Label } from '../../../components/input-text/input.styled';
import { PLAN_MANAGEMENT_VALIDATION } from '../../../utils/validations/validations';
import { useQueryClient } from 'react-query';
import ImageViewer from '../../../components/input-file/inputFileWithView';
import { CommaFormatted, handleOpenFile } from '../../../utils/global';
import moment from 'moment';
import { DATE_FORMAT, YesNoOptions } from '../../../constants/common';
import * as Yup from 'yup';
import Tag from '../../../components/lozenge/tags';
import _, { isEmpty } from 'lodash';
import ToastComponent from '../../../components/toast';
import { SettingDivider } from '../settings.styled';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { regExp, commonValidation } from '../../../utils/validations/validationRule';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import {
  useBoolean,
  useGetPlanGrantDocumentTypes,
  useGetPlanManagementPlanTypeCategory,
  useWealthlaneAwardApi,
} from '../../../hooks';
import Heading from '../../../components/typography';
import Button from '../../../components/button';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import GrantDocumentModel from './grantDocumentModel';
import InitialValuesForm from '../../../constants/initialValues';
import GrantDocumentTermAndConditionModel from './grantDocumentTermAndConditionModel';
import { DocumentContainer } from './planManagement.styled';

interface AddPlanType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  planInitialData: { data: any; id: string };
  setPlanInitialData: any;
}
const negativeDigits = /^[0-9]*$/;
const greaterThanZero = /^[1-9]\d*$/;

const esopValidation = {
  optionExpirationDate: Yup.string()
    .required('Required field')
    .matches(negativeDigits, 'Must not be decimal number')
    .matches(greaterThanZero, 'Must be greater than 0')
    .typeError('Must be only digits')
    .nullable(),
  deathExpirationOption: Yup.string()
    .required('Required field')
    .matches(negativeDigits, 'Must not be decimal number')
    .matches(greaterThanZero, 'Must be greater than 0')
    .typeError('Must be only digits')
    .nullable(),
  disablityExpirationOption: Yup.string()
    .required('Required field')
    .matches(negativeDigits, 'Must not be decimal number')
    .matches(greaterThanZero, 'Must be greater than 0')
    .typeError('Must be only digits')
    .nullable(),
  retirementExpirationOption: Yup.string()
    .required('Required field')
    .matches(negativeDigits, 'Must not be decimal number')
    .matches(greaterThanZero, 'Must be greater than 0')
    .typeError('Must be only digits')
    .nullable(),
  volutaryTerminationOption: commonValidation({ required: true, negativeDigits: true }),
  involutaryTerminationOption: Yup.string()
    .required('Required field')
    .matches(negativeDigits, 'Must not be decimal number ')
    .matches(greaterThanZero, 'Must be greater than 0')
    .typeError('Must be only digits')
    .nullable(),
  withACause: commonValidation({ required: true, negativeDigits: true }),
};

// const rsuValidation = {
//   // planTerm: Yup.number().moreThan(0, 'Must be greater than 0')
//   //   .integer('Must not be decimal')
//   //   .typeError('Must be only digits').required('Required field'),
//   // boardApprovalDate: Yup.string().required('Required field'),
//   // shareHolderApprovalDate: Yup.string().required('Required field'),
//   // evergreenPlan: Yup.string().required('Required field'),
// };

const initialGrantDocument = {
  data: InitialValuesForm.grantDocumentModel,
  id: '',
};

const PlanManagementModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  planInitialData,
  setPlanInitialData,
}: AddPlanType): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardApi();
  const PlanManagementPlanTypeCategory = useGetPlanManagementPlanTypeCategory(handleRequest);
  const queryClient = useQueryClient();
  const [validationState, setValidationState] = useState<any>(PLAN_MANAGEMENT_VALIDATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [removedId, setRemovedId] = useState<string[]>([]);
  const planLabel = (values: any): any => {
    const selectedPlan: string[] = values.planTypeCategoryIds?.map(
      (planId: any) =>
        PlanManagementPlanTypeCategory.data?.find((d: any) => d?.value === planId).label
    );
    return selectedPlan;
  };

  const handleSubmit = (values: any, documentDto: any): void => {
    setLoading(true);
    const planDocumentList = planInitialData.data?.planNameList.map((item: any) => {
      return {
        planDocumentFileName: item.file.name,
        planDocumentUniqueName: item.id,
        isDeleted: removedId.includes(item.id),
      };
    });

    // const indexValues: Record<string, string | number | boolean> = {};
    // planDocumentList?.map((item: Record<string, string>, index: number) => {
    //   Object.keys(item).forEach((k, i) => {
    //     indexValues[`planDocumentDetails[${index}].${k}`] = item[k];
    //   });
    //   return null;
    // });

    const isData = PlanManagementPlanTypeCategory?.data?.filter((item: any) =>
      values?.planTypeCategoryIds.includes(item.value)
    );
    const filterData = isData.map((item: OptionType) => item.label);

    const payload = {
      name: values.name,
      id: planInitialData.id,
      numberOfSharesAuthorized: parseInt(
        String(values.numberOfSharesAuthorized).replaceAll(',', '' ?? '')
      ),
      numberOfSharesAvailable: parseInt(
        String(values.numberOfSharesAvailable).replaceAll(',', '' ?? '')
      ),
      numberOfSharesUnAvailable: parseInt(
        String(values.numberOfSharesUnAvailable).replaceAll(',', '' ?? '')
      ),
      eligibleShareClass: values.eligibleShareClass,
      description: values.description,
      planTypeCategoryIds: values.planTypeCategoryIds,
      planTypeCategory: (values.planTypeCategory as boolean) ? values.planTypeCategory : '',
      boardApprovalDate: moment(values?.boardApprovalDate).format(),
      shareHolderApprovalDate: moment(values?.shareHolderApprovalDate).format(),
      planTerm: values.planTerm,
      dividendEligibility: values.dividendEligibility === 'Yes',
      evergreenPlan: values.evergreenPlan === 'Yes',
      evergreenCriteria: values.evergreenPlan === 'Yes' ? values.evergreenCriteria : '',
      deathExpirationOption:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values.deathExpirationOption,
      disablityExpirationOption:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values.disablityExpirationOption,
      retirementExpirationOption:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values.retirementExpirationOption,
      volutaryTerminationOption:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values.volutaryTerminationOption,
      involutaryTerminationOption:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values.involutaryTerminationOption,
      withACause:
        filterData.length === 1 && !filterData.includes('Options') ? null : values.withACause,
      planDocument: values?.planDocument.length > 0 ? values?.planDocument : [],
      planEffectiveDate: moment(values.planEffectiveDate).format(),
      optionExpirationDate:
        filterData.length === 1 && !filterData.includes('Options')
          ? null
          : values?.optionExpirationDate,

      planGrantDocuments: documentDto ? documentDto?.filter((item: any) => !item?.isDefault) : [],

      planDocumentDetails: [...planDocumentList],
      deletedPlanGrantDocuments: deletedGrantDocument?.length > 0 ? [...deletedGrantDocument] : [],
      // ...indexValues,
    };

    if (planInitialData.id != null && planInitialData.id !== '') {
      handleRequest(PlanService.putApiAwardApiAppPlanPlan(payload as any))
        .then((response) => {
          if (response != null) {
            closeModal();
            toast.success(<ToastComponent label='Success' message={'Plan edited'} />);
            void queryClient.invalidateQueries('getPlanManagementList');
            setLoading(false);

            // actions.resetForm();
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          // actions.resetForm();
        });
    } else {
      handleRequest(
        PlanService.postApiAwardApiAppPlanPlan({
          ...values,
          evergreenPlan: values.evergreenPlan === 'Yes',
          dividendEligibility: values.dividendEligibility === 'Yes',
          numberOfSharesAuthorized: Number(
            values.numberOfSharesAuthorized.replaceAll(',', '' ?? '')
          ),
          numberOfSharesAvailable: Number(values.numberOfSharesAvailable.replaceAll(',', '' ?? '')),
          numberOfSharesUnAvailable: Number(
            values.numberOfSharesUnAvailable.replaceAll(',', '' ?? '')
          ),
          planDocument: values?.planDocument,
          deathExpirationOption:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values.deathExpirationOption,
          disablityExpirationOption:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values.disablityExpirationOption,
          retirementExpirationOption:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values.retirementExpirationOption,
          volutaryTerminationOption:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values.volutaryTerminationOption,
          involutaryTerminationOption:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values.involutaryTerminationOption,
          withACause:
            filterData.length === 1 && !filterData.includes('Options') ? null : values.withACause,
          optionExpirationDate:
            filterData.length === 1 && !filterData.includes('Options')
              ? null
              : values?.optionExpirationDate,
          planGrantDocuments: documentDto?.map((item: any) => item),
        })
      )
        .then((response: any) => {
          if (response != null) {
            closeModal();
            toast.success(<ToastComponent label='Success' message={'Plan added'} />);
            void queryClient.invalidateQueries('getPlanManagementList');
            setLoading(false);
            // actions.resetForm();
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
  };

  const handleImageOpen = (name: string): void => {
    handleRequest(
      PlanService.postApiAwardApiAppPlanDownloadPlanDocument({
        planDocumentUniqueName: name,
        planId: planInitialData.id,
      })
    )
      .then((data: any) => {
        handleOpenFile(data.name, data.content);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  const handleGrantAgreementImageOpen = (id: string): void => {
    handleRequest(
      PlanService.postApiAwardApiAppPlanDownloadPlanGrantDocument({
        planId: planInitialData.id,
        id: id,
      })
    )
      .then((data: any) => {
        handleOpenFile(data.name, data.content);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const {
    value: isModalGrantOpen,
    setFalse: closeModalGrant,
    setTrue: openModalGrant,
  } = useBoolean(false, true);
  const {
    value: isModalGrantTermAndConditionOpen,
    setFalse: closeModalGrantTermAndCondition,
    setTrue: openModalGrantTermAndCondition,
  } = useBoolean(false, true);

  const planGrantDocumentType = useGetPlanGrantDocumentTypes(handleRequest);

  const [modelName, setModelName] = useState<string>();
  const [documentDto, setDocumentDto] = useState<any>([]);
  const [documentType, setDocumentType] = useState<string | number | null>();
  const [deletedGrantDocument, setDeletedGrantDocument] = useState<string[]>([]);

  const getValueByLabel = (label: string) => {
    const item = planGrantDocumentType?.data?.find((item) => item.label === label);
    return item ? setDocumentType(item.value) : null;
  };

  const initialData = {
    ...planInitialData.data,
    evergreenPlan: (planInitialData.data.evergreenPlan as boolean) ? 'Yes' : 'No',
    dividendEligibility: (planInitialData.data.dividendEligibility as boolean) ? 'Yes' : 'No',
  };

  useEffect(() => {
    if (initialData?.planGrantDocuments.length > 0) {
      setDocumentDto(
        initialData?.planGrantDocuments.map((doc: any) => ({
          ...doc,
          grantDocumentName: doc?.documentName,
          grantDocumentType: doc?.documentTypeId,
        }))
      );
    }
  }, [initialData?.planGrantDocuments?.length]);

  useEffect(() => {
    if (documentType) {
      setDocumentType(documentType);
    }
  }, [documentType]);

  /**
   * If the value is not null, then remove all non-numeric characters from the value, and then if the
   * length of the value is less than 4, set the value to the value, otherwise if the length of the value
   * is less than 7, set the value to the value with a comma after the first 3 characters, otherwise set
   * the value to the value with a comma after the first 3 characters and a comma after the next 3
   * characters.
   * @param {string} name - the name of the field
   * @param {any} setFieldValue - This is a function that is passed to the component by the Formik
   * library. It allows you to set the value of a field.
   * @param {any} value - the value of the input field
   */

  const getDocument = (label: string) => {
    // Find the ID corresponding to the label
    const id =
      planGrantDocumentType?.data &&
      planGrantDocumentType?.data.find((item: any) => item.label === label)?.value;
    // Filter data from A based on the grantDocumentType
    return documentDto.filter((item: any) => item.grantDocumentType === id);
  };
  const getEditDocument = (label: string) => {
    // Find the ID corresponding to the label
    const id =
      planGrantDocumentType?.data &&
      planGrantDocumentType?.data.find((item: any) => item.label === label)?.value;

    // Filter data from A based on the grantDocumentType
    return planInitialData?.data?.planGrantDocuments?.filter(
      (item: any) => item.documentTypeId === id
    );
  };

  // Usage example: Get data from A if label is "Grant Agreement Document"

  const [filteredGrantAgreementDocument, setFilteredGrantAgreementDocument] = useState([]);
  const [filteredGrantNoticeDocument, setFilteredGrantNoticeDocument] = useState([]);
  const [filteredTermsAndConditionDocument, setFilteredTermsAndConditionDocument] = useState([]);
  const [filteredTermsAndConditionText, setFilteredTermsAndConditionText] = useState([]);
  const [filteredTermsAndCondition, setFilteredTermsAndCondition] = useState([]);

  useEffect(() => {
    setFilteredGrantAgreementDocument(() =>
      // modalStatus === 'Edit'
      //   ? getEditDocument('Grant Agreement Document')
      getDocument('Grant Agreement Document')
    );
    setFilteredGrantNoticeDocument(() =>
      // modalStatus === 'Edit'
      //   ? getEditDocument('Grant Notice Document')
      getDocument('Grant Notice Document')
    );
    setFilteredTermsAndConditionDocument(() =>
      // modalStatus === 'Edit'
      //   ? getEditDocument('Terms And Condition Document')
      getDocument('Terms And Condition Document')
    );
    setFilteredTermsAndConditionText(() =>
      // modalStatus === 'Edit'
      //   ? getEditDocument('Terms And Condition Text')
      getDocument('Terms And Condition Text')
    );
    if (documentDto) {
      convertToBase64(documentDto?.[0]?.document?.[0]);
    }
  }, [documentDto, planGrantDocumentType?.data]);

  useEffect(() => {
    setFilteredTermsAndCondition([
      ...filteredTermsAndConditionDocument,
      ...filteredTermsAndConditionText,
    ]);
  }, [filteredTermsAndConditionDocument, filteredTermsAndConditionText]);

  const removeObjectsByName = (array: any, name: string) => {
    return array.filter((item: any) => item.grantDocumentName !== name);
  };

  const [base64String, setBase64String] = useState<any>();
  const convertToBase64 = (file: any) => {
    // const reader = new FileReader();
    // console.log('reader', reader);

    // reader.readAsDataURL(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };

  const handleDeleteGrantDocument = (id: string) => {
    if (id) {
      setDeletedGrantDocument((prevState) => [...prevState, id]);
    }
  };

  const openInNewTab = (text: any) => {
    const newWindow = window.open('', '_blank');
    newWindow?.document.write(text);
    newWindow?.document.close();
  };

  return (planInitialData.data as boolean) ? (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={Yup.object().shape(validationState)}
        onSubmit={(values: any) => handleSubmit(values, documentDto)}>
        {({ touched, handleChange, setFieldTouched, errors, values, setFieldValue }) => {
          useEffect(() => {
            if (!regExp.text.test(values?.numberOfSharesAvailable)) {
              CommaFormatted(
                values?.numberOfSharesAvailable,
                'numberOfSharesAvailable',
                setFieldValue
              );
            }

            // if (!regExp.text.test(values?.numberOfSharesAuthorized)) {
            //   CommaFormatted(
            //     values?.numberOfSharesAuthorized,
            //     'numberOfSharesAuthorized',
            //     setFieldValue
            //   );
            // }

            // if (!regExp.text.test(values?.numberOfSharesUnAvailable)) {
            //   CommaFormatted(
            //     values?.numberOfSharesUnAvailable,
            //     'numberOfSharesUnAvailable',
            //     setFieldValue
            //   );
            // }

            if (
              (modalStatus === 'Add' || modalStatus === 'Edit') &&
              (values?.numberOfSharesUnAvailable as boolean)
            ) {
              const newValue =
                parseInt(values?.numberOfSharesAuthorized?.toString()?.replaceAll(',', '')) -
                parseInt(values?.numberOfSharesUnAvailable?.toString()?.replaceAll(',', ''));
              if (values?.numberOfSharesAuthorized as boolean) {
                setFieldValue(
                  'numberOfSharesAvailable',
                  CommaFormatted(newValue, 'numberOfSharesAvailable', setFieldValue)
                );
              } else {
                setFieldValue('numberOfSharesAvailable', 0);
              }
            }
            if (Boolean(initialData) && initialData?.planTypeCategory === 'RSU,Options') {
              setValidationState({
                ...PLAN_MANAGEMENT_VALIDATION,
                ...esopValidation,
              });
            }

            if (Boolean(initialData) && initialData?.planTypeCategory === 'Options') {
              setValidationState({
                ...PLAN_MANAGEMENT_VALIDATION,
                ...esopValidation,
              });
            }
          }, [
            values?.numberOfSharesAvailable,
            values?.numberOfSharesAuthorized,
            values?.numberOfSharesUnAvailable,
          ]);

          return (
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              size='xl'
              title={`${modalStatus === 'Create' ? 'Add' : modalStatus} New Plan`}
              showToolTip={true}
              valueEdited={_.isEqual(values, initialData)}
              tooltipText={'Refer to the Plan document to populate the fields below.'}
              children={
                <Form>
                  <ModalBodyComponent>
                    <fieldset>
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[14px] '>
                        <div className='lg:col-span-2'>
                          <InputText
                            type='text'
                            label='Plan Name'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                        </div>
                        <FormSelect
                          type='text'
                          label={'Award Type'}
                          name={`planTypeCategoryIds`}
                          viewName='planTypeCategory'
                          onChange={async (value: string) => {
                            const isData = PlanManagementPlanTypeCategory?.data?.filter(
                              (item: any) => value.includes(item.value)
                            );
                            const filterData = isData.map((item: OptionType) => item.label);
                            setFieldValue('planTypeCategoryIds', value);
                            setFieldValue('planTypeCategory', filterData);
                            if (filterData.includes('Options') === true) {
                              setValidationState({
                                ...PLAN_MANAGEMENT_VALIDATION,
                                ...esopValidation,
                              });
                            } else if (filterData.includes('RSU') === true) {
                              await setValidationState({
                                ...PLAN_MANAGEMENT_VALIDATION,
                              });
                            } else {
                              setValidationState({
                                ...PLAN_MANAGEMENT_VALIDATION,
                              });
                            }
                            setFieldValue('planTypeCategoryIds', value);
                            if (value.length === 1) {
                              if (filterData?.includes('RSU') === true) {
                                // setFieldValue('optionExpirationDate', '');
                                // setFieldValue('deathExpirationOption', '');
                                // setFieldValue('disablityExpirationOption', '');
                                // setFieldValue('retirementExpirationOption', '');
                                // setFieldValue('volutaryTerminationOption', '');
                                // setFieldValue('involutaryTerminationOption', '');
                              } else {
                                setFieldValue('planTypeCategoryIds', value);
                              }
                            }
                          }}
                          errors={errors}
                          touched={touched}
                          options={PlanManagementPlanTypeCategory?.data}
                          values={values?.planTypeCategoryIds}
                          setFieldValue={setFieldValue}
                          required
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          multiple
                        />
                      </div>
                      <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[14px]'>
                        <CustomDatePicker
                          label='Board Approval Date'
                          name='boardApprovalDate'
                          onChange={handleChange}
                          arrValue={values?.boardApprovalDate}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          values={values}
                          max={new Date()}
                          defaultValue={new Date()}
                          required
                          disabled={modalStatus === 'View'}
                        />

                        <CustomDatePicker
                          label='Shareholder Approval Date'
                          name='shareHolderApprovalDate'
                          onChange={handleChange}
                          arrValue={values?.shareHolderApprovalDate}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          values={values}
                          max={new Date()}
                          defaultValue={new Date()}
                          required
                        />

                        <CustomDatePicker
                          label='Plan Effective Date'
                          name='planEffectiveDate'
                          onChange={handleChange}
                          arrValue={values?.planEffectiveDate}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          values={values}
                          min={
                            (values?.shareHolderApprovalDate as boolean) &&
                            isEmpty(values?.boardApprovalDate)
                              ? moment(values?.shareHolderApprovalDate).toDate()
                              : (values?.boardApprovalDate as boolean) &&
                                isEmpty(values?.shareHolderApprovalDate)
                              ? moment(values?.boardApprovalDate).toDate()
                              : moment(values?.shareHolderApprovalDate).toDate() >
                                moment(values?.boardApprovalDate).toDate()
                              ? moment(values?.shareHolderApprovalDate).toDate()
                              : moment(values?.boardApprovalDate).toDate()
                          }
                          max={new Date()}
                          defaultValue={new Date()}
                          required
                        />
                      </div>
                      <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[14px]  '>
                        <InputText
                          type='text'
                          label='No. of Shares Authorized'
                          name='numberOfSharesAuthorized'
                          onChange={handleChange}
                          onFocus={(e: any) => {
                            values?.numberOfSharesAuthorized.replaceAll(',', '');
                          }}
                          onBlur={(e: any) => {
                            CommaFormatted(
                              e.target.value,
                              `numberOfSharesAuthorized`,
                              setFieldValue
                            );
                            setFieldTouched(`numberOfSharesAuthorized`, true);
                          }}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        <InputText
                          type='text'
                          label='No. of Shares Unavailable'
                          name='numberOfSharesUnAvailable'
                          onChange={handleChange}
                          onFocus={(e: any) => {
                            values?.numberOfSharesUnAvailable.replaceAll(',', '');
                          }}
                          onBlur={(e: any) => {
                            CommaFormatted(
                              e.target.value,
                              `numberOfSharesUnAvailable`,
                              setFieldValue
                            );
                            setFieldTouched(`numberOfSharesUnAvailable`, true);
                          }}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        <InputText
                          type='text'
                          label='No. of Shares Available'
                          name='numberOfSharesAvailable'
                          value={
                            modalStatus === 'Add' || modalStatus === 'Edit'
                              ? Number(values?.numberOfSharesAuthorized) -
                                Number(values?.numberOfSharesUnAvailable)
                              : ''
                          }
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={true}
                          required
                        />
                        <InputText
                          type='text'
                          label='Eligible Share Class'
                          name='eligibleShareClass'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[14px]  '>
                        <InputText
                          type='year'
                          label='Plan Term'
                          name='planTerm'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        <div className=''>
                          <FormSelect
                            label='Evergreen Plan'
                            name='evergreenPlan'
                            viewName='evergreenPlan'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={YesNoOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                        </div>
                        {values.evergreenPlan === 'Yes' && (
                          <InputText
                            type='text'
                            label='Evergreen Criteria'
                            name='evergreenCriteria'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                        )}
                        <FormSelect
                          label='Dividend Eligibility'
                          name='dividendEligibility'
                          viewName='dividendEligibility'
                          containerClassName='formGroup'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={YesNoOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='grid sm:grid-cols-3 gap-x-[14px]'>
                        <div className='col-span-2'>
                          <CustomTextArea
                            label='Description'
                            name='description'
                            onChange={handleChange}
                            errors={errors}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </fieldset>
                    <SettingDivider></SettingDivider>
                    {planLabel(values).includes('Options') === true && (
                      <div className='mt-[24px]'>
                        <Tag variant='info' title='Termination Provisions for Options' />
                        <div className='grid sm:grid-cols-2 lg:grid-cols-3 mt-5 sm:gap-x-[34px] gap-y-5 '>
                          <InputText
                            type='year'
                            label='Option Expiration Term'
                            name='optionExpirationDate'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='Death'
                            name='deathExpirationOption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='Disability'
                            name='disablityExpirationOption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='Retirement'
                            name='retirementExpirationOption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='Voluntary Termination'
                            name='volutaryTerminationOption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='Involuntary Termination'
                            name='involutaryTerminationOption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <InputText
                            type='days'
                            label='With A Cause'
                            name='withACause'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                        </div>
                      </div>
                    )}

                    {modalStatus === 'View' && (
                      <div className='mt-5 grid md:grid-cols-2 lg:grid-cols-4 gap-x-[34px]'>
                        <InputText
                          type='text'
                          label='Plan Onboarded Date'
                          name='creationTime'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        <InputText
                          type='text'
                          label='Latest Modified Date'
                          name='lastModificationTime'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                    )}
                    <div className='gap-3 justify-between mt-3'>
                      <div>
                        <Label htmlFor={'planDocument'} className={''}>
                          {modalStatus === 'View' ? '' : 'Upload'} Plan Documents
                        </Label>
                        <ImageViewer
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          modalStatus={modalStatus}
                          values={values}
                          errors={errors}
                          touched={touched}
                          accept='.pdf, .jpg, .jpeg, .png, .docx, .doc'
                          name='planDocument'
                          listingName='planNameList'
                          loading={loading}
                          removedId={removedId}
                          handleImageOpen={handleImageOpen}
                          setRemovedId={setRemovedId}
                          isBase64={true}
                        />
                      </div>

                      <div className='mt-8'>
                        <Heading title={'Grant Documents'} variant='body' />

                        <div className='mt-8'>
                          {filteredGrantAgreementDocument &&
                            filteredGrantAgreementDocument?.length > 0 && (
                              <DocumentContainer>
                                {filteredGrantAgreementDocument?.map((details: any) => (
                                  <ImagePreview key={1} className='cursor-pointer'>
                                    <div
                                      className='image-wrapper cursor-pointer'
                                      title={details?.grantDocumentName}
                                      // className='cursor-pointer'
                                      onClick={
                                        () =>
                                          modalStatus !== 'Add' &&
                                          handleGrantAgreementImageOpen(details?.id)
                                        /*  : handleOpenFile(
                                              details?.grantDocumentName,
                                              details?.document
                                            ) */
                                      }>
                                      <>
                                        <span className='material-symbols-outlined image-icon'>
                                          draft
                                        </span>
                                        <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                      </>
                                    </div>

                                    <div>
                                      <div
                                        className='image-cross'
                                        onClick={() => {
                                          const removeFile = removeObjectsByName(
                                            filteredGrantAgreementDocument,
                                            details?.grantDocumentName
                                          );
                                          const removeDtoFile = removeObjectsByName(
                                            documentDto,
                                            details?.grantDocumentName
                                          );
                                          // const removeFile = values[listingName]?.filter(
                                          //   (item: any) => item.id !== value.id
                                          // );
                                          // setFieldValue(listingName, removeFile);
                                          // setRemovedId(removedId.concat(value.id));
                                          setFilteredGrantAgreementDocument(removeFile);
                                          handleDeleteGrantDocument(details?.id);
                                          setDocumentDto(removeDtoFile);
                                        }}>
                                        <span className='material-symbols-outlined image-delete'>
                                          delete
                                        </span>
                                      </div>
                                    </div>
                                  </ImagePreview>
                                ))}
                              </DocumentContainer>
                            )}

                          <Button
                            as='Create'
                            type='button'
                            variant={'primary-outline'}
                            title={'Add Grant Agreement'}
                            icon={'add_circle_outline'}
                            onClick={() => {
                              openModalGrant();
                              setModelName('Grant Agreement');
                              getValueByLabel('Grant Agreement Document');
                              setFieldValue('planGrantDocumentsNameList', ['changed']);
                            }}
                          />
                        </div>
                        <div className='mt-8'>
                          {filteredGrantNoticeDocument && filteredGrantNoticeDocument?.length > 0 && (
                            <DocumentContainer>
                              {filteredGrantNoticeDocument?.map((details: any) => (
                                <ImagePreview
                                  key={1}
                                  className='cursor-pointer'
                                  onClick={() =>
                                    /*  handleImageOpen(value.file.uniqueName) */ console.log('val')
                                  }>
                                  <div
                                    className='image-wrapper cursor-pointer'
                                    title={details?.grantDocumentName}
                                    // className='cursor-pointer'
                                    onClick={
                                      () =>
                                        modalStatus !== 'Add' &&
                                        handleGrantAgreementImageOpen(details?.id)
                                      // : handleOpenFile(
                                      //     details?.grantDocumentName,
                                      //     details?.document
                                      //   )
                                    }>
                                    <>
                                      <span className='material-symbols-outlined image-icon'>
                                        draft
                                      </span>
                                      <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                    </>
                                  </div>

                                  <div>
                                    <div
                                      className='image-cross'
                                      onClick={() => {
                                        const removeFile = removeObjectsByName(
                                          filteredGrantAgreementDocument,
                                          details?.grantDocumentName
                                        );
                                        const removeDtoFile = removeObjectsByName(
                                          documentDto,
                                          details?.grantDocumentName
                                        );
                                        setFilteredGrantNoticeDocument(removeFile);
                                        handleDeleteGrantDocument(details?.id);
                                        setDocumentDto(removeDtoFile);
                                      }}>
                                      <span className='material-symbols-outlined image-delete'>
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                </ImagePreview>
                              ))}
                            </DocumentContainer>
                          )}
                          <Button
                            as='Create'
                            type='button'
                            variant={'primary-outline'}
                            title={'Add Grant Notice'}
                            icon={'add_circle_outline'}
                            onClick={() => {
                              openModalGrant();
                              setModelName('Grant Notice');
                              getValueByLabel('Grant Notice Document');
                              setFieldValue('planGrantDocumentsNameList', ['changed']);
                            }}
                          />
                        </div>
                        <div className='mt-8'>
                          {filteredTermsAndConditionDocument &&
                            filteredTermsAndConditionDocument?.length > 0 && (
                              <DocumentContainer>
                                {filteredTermsAndConditionDocument?.map((details: any) => (
                                  <ImagePreview
                                    key={1}
                                    className='cursor-pointer'
                                    onClick={() =>
                                      /*  handleImageOpen(value.file.uniqueName) */ console.log(
                                        'val'
                                      )
                                    }>
                                    <div
                                      className='image-wrapper cursor-pointer'
                                      title={details?.grantDocumentName}
                                      // className='cursor-pointer'
                                      onClick={
                                        () =>
                                          modalStatus !== 'Add' &&
                                          handleGrantAgreementImageOpen(details?.id)
                                        // : handleOpenFile(
                                        //     details?.grantDocumentName,
                                        //     details?.document
                                        //   )
                                      }>
                                      <>
                                        <span className='material-symbols-outlined image-icon'>
                                          draft
                                        </span>
                                        <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                      </>
                                    </div>

                                    <div>
                                      <div
                                        className='image-cross'
                                        onClick={() => {
                                          const removeFile = removeObjectsByName(
                                            filteredGrantAgreementDocument,
                                            details?.grantDocumentName
                                          );
                                          const removeDtoFile = removeObjectsByName(
                                            documentDto,
                                            details?.grantDocumentName
                                          );
                                          setFilteredGrantNoticeDocument(removeFile);
                                          handleDeleteGrantDocument(details?.id);
                                          setDocumentDto(removeDtoFile);
                                        }}>
                                        <span className='material-symbols-outlined image-delete'>
                                          delete
                                        </span>
                                      </div>
                                    </div>
                                  </ImagePreview>
                                ))}
                              </DocumentContainer>
                            )}
                          <Button
                            as='Create'
                            type='button'
                            variant={'primary-outline'}
                            title={'Add Terms and Conditions Document'}
                            icon={'add_circle_outline'}
                            onClick={() => {
                              openModalGrant();
                              setModelName('Term and Condition');
                              setDocumentType(3);
                              getValueByLabel('Terms And Condition Document');
                              setFieldValue('planGrantDocumentsNameList', ['changed']);
                            }}
                          />
                        </div>
                        <div className='mt-8'>
                          {filteredTermsAndConditionText &&
                            filteredTermsAndConditionText?.length > 0 && (
                              <DocumentContainer>
                                {filteredTermsAndConditionText?.map((details: any) => (
                                  <ImagePreview
                                    key={1}
                                    className='cursor-pointer'
                                    onClick={() =>
                                      /*  handleImageOpen(value.file.uniqueName) */ console.log(
                                        'val'
                                      )
                                    }>
                                    <div
                                      className='image-wrapper cursor-pointer'
                                      title={details?.grantDocumentName}
                                      // className='cursor-pointer'
                                      onClick={() =>
                                        modalStatus !== 'Add' &&
                                        openInNewTab(
                                          details?.grantTermsAndConditionText ||
                                            details?.termsAndConditionText
                                        )
                                      }>
                                      <>
                                        <span className='material-symbols-outlined image-icon'>
                                          draft
                                        </span>
                                        <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                      </>
                                    </div>

                                    <div>
                                      <div
                                        className='image-cross'
                                        onClick={() => {
                                          const removeFile = removeObjectsByName(
                                            filteredGrantAgreementDocument,
                                            details?.grantDocumentName
                                          );
                                          const removeDtoFile = removeObjectsByName(
                                            documentDto,
                                            details?.grantDocumentName
                                          );
                                          setFilteredGrantNoticeDocument(removeFile);
                                          handleDeleteGrantDocument(details?.id);
                                          setDocumentDto(removeDtoFile);
                                        }}>
                                        <span className='material-symbols-outlined image-delete'>
                                          delete
                                        </span>
                                      </div>
                                    </div>
                                  </ImagePreview>
                                ))}
                              </DocumentContainer>
                            )}
                          <Button
                            as='Create'
                            type='button'
                            variant={'primary-outline'}
                            title={'Add Terms and Conditions Text'}
                            icon={'add_circle_outline'}
                            onClick={() => {
                              openModalGrant();
                              setModelName('Term and Condition Text');
                              setDocumentType(3);
                              getValueByLabel('Terms And Condition Text');
                              setFieldValue('planGrantDocumentsNameList', ['changed']);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBodyComponent>
                  <ModalFooterComponent
                    modalStatus={modalStatus}
                    onCloseModal={() => {
                      closeModal();
                    }}
                    valueChanged={
                      deletedGrantDocument?.length > 0
                        ? false
                        : _.isEqual(values, initialData) /* ||
                      (documentDto?.length < 0 && documentDto?.length) === 0
                        ? false
                        : true */
                    }
                    loading={loading}
                    // addTitle={'Plan'}
                  />
                </Form>
              }
            />
          );
        }}
      </Formik>
      <AnimationWrapper>
        {isModalGrantOpen && (
          <GrantDocumentModel
            isModalOpen={isModalGrantOpen}
            closeModal={closeModalGrant}
            modalStatus={modalStatus}
            initialData={planInitialData}
            id={initialData?.data?.id}
            modelName={modelName}
            documentDto={documentDto}
            setDocumentDto={setDocumentDto}
            documentType={documentType}
            setDocumentType={setDocumentType}
          />
        )}
      </AnimationWrapper>
      <AnimationWrapper>
        {isModalGrantTermAndConditionOpen && (
          <GrantDocumentTermAndConditionModel
            isModalOpen={isModalGrantTermAndConditionOpen}
            closeModal={closeModalGrantTermAndCondition}
            modalStatus={modalStatus}
            // modalLoading={modalLoading}
            initialData={initialGrantDocument}
            // vestingTypeOptions={vestingTypeOptions}
            // setModalStatus={setModalStatus}
            id={initialData?.data?.performanceRuleId}
            // rowData={rowData}
            modelName={modelName}
            documentDto={documentDto}
            setDocumentDto={setDocumentDto}
            documentType={documentType}
            setDocumentType={setDocumentType}
          />
        )}
      </AnimationWrapper>
    </>
  ) : (
    <></>
  );
};

export default PlanManagementModel;
