import moment from 'moment';

export const VestingMonthsOptions = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 12,
    value: 12,
  },
];

export const SUPPORTED_IMAGE_FORMATS = ['jpg', 'jpeg', 'gif', 'png'];
export const FILE_SIZE = 1024 * 1024 * 10;
export const SUPPORTED_FORMATS = [
  { type: 'jpg', format: 'image/jpg' },
  { type: 'jpeg', format: 'image/jpeg' },
  { type: 'png', format: 'image/png' },
  { type: 'pdf', format: 'application/pdf' },
  { type: 'doc', format: 'application/msword' },
  {
    type: 'docx',
    format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
];
export const PDF_SUPPORTED_FORMATS = [{ type: 'pdf', format: 'application/pdf' }];

export interface ChartFilter {
  companyId?: string | undefined;
  fromDate?: string;
  toDate?: string;
}

export type SortByType = '1M' | 'YTD' | '1Y' | '3Y' | '5Y';

export const accountStatusOptions = [
  { label: 'REGISTERED', value: 'REGISTERED' },
  { label: 'UNREGISTERED', value: 'UNREGISTERED' },
];

export const SIDEBAR_LISTS = [
  { name: 'My Account', icon: 'account_box' },
  { name: 'System Users & Roles', icon: 'groups' },
  { name: 'Award Management', icon: 'import_contacts' },
  { name: 'Approval Console', icon: 'list_alt' },
  { name: 'Configuration', icon: 'handyman' },
  { name: 'Others', icon: 'description' },
];

export const PreClearanceRequirement = [
  {
    label: 'Custom Dates',
    value: 'CustomDates',
  },
  {
    label: 'At All Times',
    value: 'AtAllTimes',
  },
];

export const preClearanceSelectType = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Group',
    value: 'group',
  },
];

export const EqualityOperatorOptions = [
  {
    label: 'Equals',
    value: 'Equals',
  },
  {
    label: 'Not Equals',
    value: 'NotEquals',
  },
];

export const YesNoOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const SortingAscDesc = [
  {
    label: 'Ascending',
    value: 1,
  },
  {
    label: 'Descending',
    value: 2,
  },
];
export const ReportTypes = [
  {
    label: 'Standard Reports',
    value: 'standardReport',
  },
  {
    label: 'Custom Reports',
    value: 'customReport',
  },
];
export const YesNoBooleanOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];
export const EmailStatusOptions = [
  {
    label: 'Send',
    value: true,
  },
  {
    label: 'Not Send',
    value: false,
  },
];
export const UserStatusOptions = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Deactivated',
    value: false,
  },
];

export const ActiveOptions = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'InActive',
  },
];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY LT';

export const OPTION_REPORT = 'Option';
export const OPTION_VESTING_REPORT = 'Option Vesting';
export const PARTICIPANT_REPORT = 'Participant';
export const RSU_CANCELLATION_REPORT = 'Rsu-Cancellation';
export const RSU_VESTING_REPORT = 'Rsu-Vesting';
export const TERMINATION_REPORT = 'Termination';
export const ROLE = 'Role';
export const RETIREMENT_ELIGIBILITY_REPORT = 'Retirement-Eligibility';

export const RELOAD_PERMISSION_MESSAGE =
  'Your permission has changed. The current page will be refreshed to load your new permissions.';
export const REDIRECT_PERMISSION_MESSAGE =
  'Your permission has changed. You currently do not have permission to this features. You will be redirected to the overview page. Please work with your access control team if you would like to have access to this feature.';

// Heading Tags
export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];
export const OTHER_FIELDS = ['Need Help?', 'Legal Policies'];

// Roles Permission List
// export const ROLES_PERMISSION_LIST = ['View', 'Create', 'Edit', 'Delete'];
export const ROLES_PERMISSION_LIST = ['View', 'Create', 'Edit', 'Delete', 'Deactivate'];
export const PARTICIPANT_ACTIVITIES_ORDER = [
  'No. of Unregistered Participants',
  'No. of Unique Logins',
  'No. of Terminated Participants',
  'No. of Participants Awarded',
  'No. of Retirement Eligible Participants',
];

export const PARTICIPANT_REPORT_COLUMN_LIST = [
  'Name',
  'Participant Demographics',
  'Employment Details',
  'Grant & Award Details - Value',
  'Grant & Award Details - Quantity',
];

export const initialHeight = window.innerHeight;

// Global font size
export const DEFAULT_FONT_SIZE = 10;

// Global Total Counts for not pagination
export const TotalCountSize = 100;

// Vesting Frequency Options
export const VestingFrequencyOptions = [
  {
    label: 'Monthly',
    value: 1,
  },
  {
    label: 'Quarterly',
    value: 3,
  },
  {
    label: 'Semi-Annually',
    value: 6,
  },
  {
    label: 'Annually',
    value: 12,
  },
];

export const OPTIONS_REPORT_DATE_OPTIONS = [
  {
    label: 'MTD',
    value: 1,
  },
  {
    label: 'Last Month',
    value: 2,
  },
  {
    label: 'QTD',
    value: 3,
  },
  {
    label: 'Last Quarter',
    value: 4,
  },
  {
    label: 'YTD',
    value: 5,
  },
  {
    label: 'Prior Year',
    value: 6,
  },
  {
    label: 'Custom',
    value: 7,
  },
];

// Stock Data
export const StockTabData = [
  {
    name: '1M',
    fromDate: moment(new Date()).subtract(1, 'month').format(DATE_FORMAT),
    precisionBy: 'day',
  },
  {
    name: 'YTD',
    fromDate: moment(new Date()).subtract(11, 'month').format(DATE_FORMAT),
    precisionBy: 'month',
  },
  {
    name: '1Y',
    fromDate: moment(new Date()).subtract(1, 'year').format(DATE_FORMAT),
    precisionBy: 'month',
  },
  {
    name: '3Y',
    fromDate: moment(new Date()).subtract(3, 'year').format(DATE_FORMAT),
    precisionBy: 'month',
  },
  {
    name: '5Y',
    fromDate: moment(new Date()).subtract(5, 'year').format(DATE_FORMAT),
    precisionBy: 'year',
  },
];

// Term And Condition Pages
export const TermAndConditionPage = {
  ParticipantOnboarding: { name: 'Participant Onboarding' },
  RSUAcceptance: { name: 'RSU Acceptance' },
  OptionsAcceptance: { name: 'Options Acceptance' },
  RSUElect: { name: 'RSU Elect' },
  OptionsExercise: { name: 'Options Exercise' },
};

// Account Activation Status
export const ACCOUNT_ACTIVATION_STATUS_OPTIONS = [
  {
    label: 'Registered',
    value: 'registered',
  },
  {
    label: 'Unregistered',
    value: 'unregistered',
  },
];

// Tax Certification Status
export const TAX_CERTIFICATION_STATUS_OPTIONS = [
  {
    label: 'Certified',
    value: 'certified',
  },
  {
    label: 'Uncertified',
    value: 'uncertified',
  },
];

// Tax Certification Status
export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

// Tax User Defined Status
export const TAX_TOGGLE_OPTIONS = [
  {
    label: 'Include',
    value: 'And',
  },
  {
    label: 'Exclude',
    value: 'Not',
  },
  {
    label: 'Either',
    value: 'Or',
  },
];

// Tax Certification Status
export const REPORT_EXPORT_OPTIONS = [
  {
    label: 'All Columns',
    value: 'All Columns',
  },
  {
    label: 'Visible Columns',
    value: 'Visible Columns',
  },
];

export const ElectionList = [
  {
    label: 'Sell All',
    value: 'sellAll',
  },
  {
    label: 'Sell To Cover',
    value: 'sellToCover',
  },
  {
    label: 'Withhold To Cover',
    value: 'holdToCover',
  },
];

export const SORTED_MODULE_PERMISSION = [
  'Companies',
  'CustomerRoles',
  'CustomerUsers',
  'SystemApprovers',
  'CustomerTitles',
  'CustomerDepartments',
  'CustomerEmployeeTypes',
  'Participants',
  'ParticipantBroadcastMessages',
  'Plans',
  'RSUAwards',
  'OptionAwards',
  'VestingTemplates',
  'SettlementConfigs',
  'ElectionWindowConfigurations',
  'TermsAndConditions',
  'TradePreClearance',
  'TradePreClearanceTransactionPeriodConfigs',
  'TradingBlackOut',
  'RetirementEligibilityConfigs',
  'UserDefinedFields',
  'Banks',
  'CompanyTax',
  'HRIntergration',
];

export const SORTED_FIELD_PERMISSION = [
  'SSN',
  'TaxId',
  'FirstMiddleLastName',
  'DOB',
  'EmployeeId',
  'EmployeeEmail',
  'PhoneNumber',
  'CostCenter',
  'JobTitle',
  'Department',
  'EmployeeWorkAddress',
  'EmployeeAddress',
];
