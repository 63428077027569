import moment from 'moment';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import Button from '../../../components/button';
import Tag from '../../../components/lozenge/tags';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import NoDataFound from '../../../components/no-data-found';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import ViewUploadedDoc from '../../../components/view-uploaded-doc';
import { DATE_FORMAT, SUPPORTED_FORMATS } from '../../../constants/common';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { GrantService, PlanService } from '../../../services/wealthlane-award-services';
import { type ModalStatusType } from '../../../types/common';
import {
  CommaFormatted,
  calculateAvailableShares,
  getFileExtension,
  handleOpenFile,
  setDashAsEmptyValue,
} from '../../../utils/global';
import GrantManagementModal from '../grant-management/grantModal';
import { SettingSystemUserHeader } from '../system-users/systemUserStyled';
import {
  PlanContainer,
  PlanContainerDetails,
  PlanContainerHeader,
  PlanHeaderButtons,
  PlanHeaderTitle,
  PlanUploadedDetails,
  BtnGroup,
  Divider,
  StatusBox,
  SmallButton,
} from './planManagement.styled';
import PlanManagementModel from './planManagementModel';
import { SkeletonLine } from '../../../components/skeleton';
import { ActionButton } from '../../../components/vertical-dot/popper';
import { useBoolean, useGetPlanManagementList, useWealthlaneAwardApi } from '../../../hooks';
import { InputField } from '../../participants/participant.styled';
import Spinners from '../../../components/spinner';
import { isEmpty } from 'lodash';

export const planInitialValues = {
  data: InitialValuesForm.planManagementModel,
  id: '',
};

const PlanManagement = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const {
    value: isOpenGrant,
    setFalse: closeGrantModal,
    setTrue: openGrantModal,
  } = useBoolean(false);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Create');
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [planInitialData, setPlanInitialData] = useState(planInitialValues);
  const [importLoading, setImportLoading] = useState(false);

  const initialValues = {
    data: InitialValuesForm.grantManagement,
    id: '',
    planId: planInitialData.id,
  };

  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);
  const { handleRequest, isLoading: awardLoader } = useWealthlaneAwardApi();

  const { data: planData, isLoading } = useGetPlanManagementList(handleRequest);

  const handleImageOpen = (uniqueName: string, planId: string): void => {
    handleRequest(
      PlanService.postApiAwardApiAppPlanDownloadPlanDocument({
        planDocumentUniqueName: uniqueName,
        planId,
      })
    )
      .then((data: any) => {
        handleOpenFile(data.name, data.content);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  const handlePlanGrantDocumentImageOpen = (id: string, planId: string): void => {
    handleRequest(
      PlanService.postApiAwardApiAppPlanDownloadPlanGrantDocument({
        planId: planId,
        id: id,
      })
        .then((data: any) => {
          handleOpenFile(data.name, data.content);
        })
        .catch((error: any) => {
          console.log('error', error);
        })
    );
  };

  const updateUserData = (id: string): void => {
    void handleRequest(PlanService.getApiAwardApiAppPlanPlanByIdForUpdate(id)).then((data: any) => {
      if (data != null) {
        const filteredData = {
          ...data,
          description: data.description ?? '',
          planTypeCategoryIds: data?.planType?.map((item: Record<string, string>) => item.id),
          planTypeCategory: data?.planType
            ?.map((item: Record<string, string>) => item.name)
            .join(','),
          planEffectiveDate: moment(data.planEffectiveDate).format(DATE_FORMAT),
          boardApprovalDate: (data?.boardApprovalDate as boolean)
            ? moment(data.boardApprovalDate).format(DATE_FORMAT)
            : null,
          shareHolderApprovalDate: (data?.shareHolderApprovalDate as boolean)
            ? moment(data.shareHolderApprovalDate).format(DATE_FORMAT)
            : null,
          creationTime: moment(data.creationTime).format(DATE_FORMAT),
          lastModificationTime: moment(data.lastModificationTime).format(DATE_FORMAT),
          numberOfSharesAuthorized: CommaFormatted(data.numberOfSharesAuthorized),
          numberOfSharesAvailable: CommaFormatted(data.numberOfSharesAvailable),
          numberOfSharesUnAvailable: CommaFormatted(data.numberOfSharesUnAvailable),
          planDocument: [],
          planNameList: data.planDocument.map((item: any) => {
            const fileName = item.planDocumentFileName;
            return {
              file: {
                name: fileName,
                uniqueName: item.planDocumentUniqueName,
                type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
                  ?.format,
              },
              id: item.planDocumentUniqueName,
              src: '',
            };
          }),
          planGrantDocuments: data?.planGrantDocuments?.map((doc: any) => ({
            ...doc,
            isDefault: true,
          })),
          planGrantDocumentsNameList: data?.planGrantDocuments?.map((item: any) => {
            const fileName = item.documentName;
            return {
              file: {
                name: fileName,
                uniqueName: item.id,
                type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
                  ?.format,
              },
              id: item.id,
              src: '',
            };
          }),
        };
        setPlanInitialData({ id, data: filteredData });
        openModal();
      }
    });
  };

  // const getUserData = (id: string): void => {
  //   void handleRequest(PlanService.getApiAwardApiAppPlanPlanById(id)).then((data: any) => {
  //     if (data != null) {
  //       const filteredData: any = {
  //         ...data,
  //         description: data.description ?? '',
  //         planTypeCategoryIds: data?.planType?.map((item: Record<string, string>) => item.id),
  //         planTypeCategory: data?.planType
  //           ?.map((item: Record<string, string>) => item.name)
  //           .join(','),
  //         planEffectiveDate: moment(data.planEffectiveDate).format(DATE_FORMAT),
  //         boardApprovalDate: (data?.boardApprovalDate as boolean)
  //           ? moment(data.boardApprovalDate).format(DATE_FORMAT)
  //           : null,
  //         shareHolderApprovalDate: (data?.shareHolderApprovalDate as boolean)
  //           ? moment(data.shareHolderApprovalDate).format(DATE_FORMAT)
  //           : null,
  //         creationTime: moment(data.creationTime).format(DATE_FORMAT),
  //         lastModificationTime: moment(data.lastModificationTime).format(DATE_FORMAT),

  //         planDocument: [],
  //         planNameList: data.planDocument.map((item: any) => {
  //           const fileName = item.planDocumentFileName;
  //           return {
  //             file: {
  //               name: fileName,
  //               uniqueName: item.planDocumentUniqueName,
  //               type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
  //                 ?.format,
  //             },
  //             id: item.planDocumentUniqueName,
  //             src: '',
  //           };
  //         }),
  //       };
  //       setPlanInitialData({ id, data: filteredData });
  //       openGrantModal();
  //     }
  //   });
  // };

  // const getUserData = (id: string): void => {
  //   void handleRequest(PlanService.getApiAwardApiAppPlanPlanById(id)).then((data: any) => {
  //     if (data != null) {
  //       const filteredData: any = {
  //         ...data,
  //         description: data.description ?? '',
  //         planTypeCategoryIds: data?.planType?.map((item: Record<string, string>) => item.id),
  //         planTypeCategory: data?.planType
  //           ?.map((item: Record<string, string>) => item.name)
  //           .join(','),
  //         planEffectiveDate: moment(data.planEffectiveDate).format(DATE_FORMAT),
  //         boardApprovalDate: (data?.boardApprovalDate as boolean)
  //           ? moment(data.boardApprovalDate).format(DATE_FORMAT)
  //           : null,
  //         shareHolderApprovalDate: (data?.shareHolderApprovalDate as boolean)
  //           ? moment(data.shareHolderApprovalDate).format(DATE_FORMAT)
  //           : null,
  //         creationTime: moment(data.creationTime).format(DATE_FORMAT),
  //         lastModificationTime: moment(data.lastModificationTime).format(DATE_FORMAT),

  //         planDocument: [],
  //         planNameList: data.planDocument.map((item: any) => {
  //           const fileName = item.planDocumentFileName;
  //           return {
  //             file: {
  //               name: fileName,
  //               uniqueName: item.planDocumentUniqueName,
  //               type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
  //                 ?.format,
  //             },
  //             id: item.planDocumentUniqueName,
  //             src: '',
  //           };
  //         }),
  //       };
  //       setPlanInitialData({ id, data: filteredData });
  //       openGrantModal();
  //     }
  //   });
  // };

  const getUserData = (id: string): void => {
    setPlanInitialData({ id, data: { ...planInitialValues?.data } });
  };

  const actions = [
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        updateUserData(id);
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      onClick: (id: string) => {
        setModalStatus('Delete');
        setSelectedPlan(id);
        openConfirmModal();
      },
    },
  ];

  const getActions = (rowData: Record<string, string | any>): any => {
    let newActions = [];
    if (rowData.planApprovalStatus === 'Rejected') {
      newActions = actions.filter((item: any) => item.action !== 'Edit');
    } else {
      newActions = actions;
    }
    return newActions;
  };

  const handleDelete = (): void => {
    setLoading(true);
    handleRequest(PlanService.deleteApiAwardApiAppPlanPlan(selectedPlan))
      .then((data: any) => {
        if (data != null) {
          toast.success(<ToastComponent label='Success' message='Plan deleted' />);
          void queryClient.invalidateQueries('getPlanManagementList');
          closeConfirmModal();
          setLoading(false);
        } else {
          setLoading(false);
          closeConfirmModal();
        }
      })
      .catch(() => {
        setLoading(false);
        closeConfirmModal();
      });
  };

  const getDaysOrYearValue = (value: number | string, unit: string) => {
    return `${value} ${Number(value) > 0 ? (Number(value) > 1 ? `${unit}s` : unit) : ''}`;
  };

  const importExcel = (event: any, planId: string): void => {
    if (!isEmpty(event.target.files)) {
      setImportLoading(true);

      const payload = {
        planId,
        File: event.target.files[0],
      };

      handleRequest(GrantService.postApiAwardApiAppGrantImportGrants(payload))
        .then((data: any) => {
          if (data) {
            toast.success(<ToastComponent label='Success' message='Grant Template imported.' />);
            queryClient.invalidateQueries('getPlanManagementList');
            setImportLoading(false);
          }
        })
        .catch((error: any) => {
          console.log('error', error);
          setImportLoading(false);
        })
        .finally(() => {
          setImportLoading(false);
        });

      event.target.value = null;
    }
  };
  const downloadTemplate = (name: string): void => {
    handleRequest(GrantService.postApiAwardApiAppGrantDownloadGrantImportSample())
      .then((data: any) => {
        if (data != null) {
          handleOpenFile(`${name}.xlsx`, data.content);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const openInNewTab = (text: any) => {
    const newWindow = window.open('', '_blank');
    newWindow?.document.write(text);
    newWindow?.document.close();
  };

  return (
    <>
      <SettingSystemUserHeader className='flex flex-col lg:flex-row justify-between lg:items-center gap-4 lg:gap-0'>
        <div>
          <Heading variant='h3' title='Equity Compensation Plan Management' />
          <Heading
            variant='body-s'
            title='List of equity compensation plans on Wealthlane system.'
            color={`${COLORS.Gray}`}
          />
        </div>
        <div className={`flex flex-col sm:flex-row gap-4`}>
          <Button
            variant={'primary-dark'}
            title={'View All Grant'}
            onClick={() => {
              navigate(`/settings/organization-settings/grant-management`);
            }}
          />
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Plan'}
            icon={'add_circle_outline'}
            onClick={() => {
              setPlanInitialData(planInitialValues);
              setModalStatus('Add');
              openModal();
            }}
          />
        </div>
      </SettingSystemUserHeader>

      {isLoading && (
        <>
          {Array.from(Array(Number(1)).keys()).map((item) => (
            <PlanContainer>
              <PlanContainerHeader>
                <PlanHeaderTitle className='w-full'>
                  <SkeletonLine size='h5' width='200px' />
                  <SkeletonLine size='body-xs' width='100px' />
                </PlanHeaderTitle>
                <PlanHeaderButtons>
                  <SkeletonLine size='h3' width='200px' />
                  <SkeletonLine size='table' width='60px' />
                </PlanHeaderButtons>
              </PlanContainerHeader>
              <PlanContainerDetails className='grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2'>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
              </PlanContainerDetails>

              <PlanContainerDetails className='grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2'>
                <SkeletonLine size='h1' />
              </PlanContainerDetails>

              <Divider />
              <PlanContainerDetails className='grid grid-cols-6'>
                <div>
                  <SkeletonLine size='body-xs' width='100px' />
                  <SkeletonLine size='h5' />
                </div>
              </PlanContainerDetails>
            </PlanContainer>
          ))}
        </>
      )}
      {!isLoading &&
        (planData != null && planData.length > 0 ? (
          <>
            {planData?.map((item: any, index: number) => {
              return (
                <PlanContainer>
                  <PlanContainerHeader>
                    <PlanHeaderTitle>
                      <div>
                        <Heading
                          variant='h5'
                          className={(item?.isExpired as boolean) ? 'line-through' : ''}
                          title={`${String(item?.planName ?? '')}`}
                          color={(item?.isExpiring as boolean) ? COLORS.Red : COLORS.Black}
                        />
                        {item?.planTypeCategories?.map((type: string) => (
                          <Tag variant='default-outline' title={type} />
                        ))}
                        {((item?.isExpired as boolean) || (item?.isExpiring as boolean)) && (
                          <Tag
                            variant='error'
                            title={(item?.isExpired as boolean) ? 'Expired' : 'Expiring'}
                          />
                        )}
                      </div>
                      <Heading
                        variant='body-xs'
                        title={`Modified on ${
                          (item?.lastModifiedDate as boolean)
                            ? moment(item?.lastModifiedDate).format(DATE_FORMAT)
                            : moment(item?.planCreationDate).format(DATE_FORMAT)
                        }`}
                        color={`${COLORS.SpanishGray}`}
                      />
                    </PlanHeaderTitle>
                    <PlanHeaderButtons>
                      <InputField>
                        <Button
                          variant={'secondary-white'}
                          title={'Download Grant Template'}
                          icon={'cloud_upload'}
                          onClick={() => {
                            downloadTemplate('Grant Template');
                          }}
                        />
                        <SmallButton>
                          <input
                            className='input-file'
                            id={`my-file-${index}`}
                            type='file'
                            accept='.xlsx, .xls'
                            onChange={(e: any) => {
                              importExcel(e, item?.planId);
                              // fileImport(e);
                            }}
                          />
                          {!importLoading ? (
                            <label
                              className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left'
                              htmlFor={`my-file-${index}`}>
                              Import Grant Bulk XLS
                            </label>
                          ) : (
                            <label
                              className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left flex gap-2 items-center'
                              htmlFor={`my-file-${index}`}>
                              <Spinners className='spinner-container ' /> Import Grant Bulk XLS
                            </label>
                          )}
                        </SmallButton>
                      </InputField>
                      <>
                        {!(item?.planApprovalStatus?.includes('Rejected') === true) && (
                          <div className=''>
                            <BtnGroup>
                              <Button
                                variant={'secondary-white'}
                                title={
                                  <div className='flex gap-2 items-center'>
                                    View Grant
                                    {
                                      <Tag
                                        variant='default-outline'
                                        className='btnTag'
                                        title={`${String(item.noOfGrants ?? '')}`}
                                      />
                                    }
                                  </div>
                                }
                                onClick={() => {
                                  /* Navigating to a new page. */
                                  navigate(`/settings/organization-settings/grant-management`, {
                                    state: {
                                      planId: item.planId,
                                    },
                                  });
                                }}
                              />

                              <Button
                                as='Create'
                                variant={'secondary-white'}
                                title={'Add Grant'}
                                icon={'add_circle_outline'}
                                onClick={() => {
                                  getUserData(item.planId ?? '');
                                  // setPlanInitialData(planInitialValues);
                                  setModalStatus('Add');
                                  openGrantModal();
                                }}
                              />
                            </BtnGroup>
                          </div>
                        )}
                        <ActionButton as={'Button'} actions={getActions(item)} id={item?.planId} />
                      </>
                    </PlanHeaderButtons>
                  </PlanContainerHeader>
                  <PlanContainerDetails className='grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2'>
                    <div>
                      <Heading
                        variant='body-xs'
                        title='Board Approval Date'
                        color={`${COLORS.Gray}`}
                        className='whitespace-nowrap'
                      />
                      <Heading
                        variant='h5'
                        title={`${moment(item?.boardApprovalDate).format(DATE_FORMAT)}`}
                      />
                    </div>

                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Shareholder Approval Date'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${moment(item?.shareHolderApprovalDate).format(DATE_FORMAT)}`}
                      />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Plan Effective Date'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${moment(item?.planEffectiveDate).format(DATE_FORMAT)}`}
                      />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Plan Onboarded Date'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${moment(item?.planCreationDate).format(DATE_FORMAT)}`}
                      />
                    </div>

                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='No. of Shares Authorized'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${CommaFormatted(item?.numberOfSharesAuthorized)}`}
                      />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='No. of Shares Unavailable'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${CommaFormatted(item?.numberOfSharesUnAvailable)}`}
                      />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='No. of Shares Available'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${CommaFormatted(item?.numberOfSharesAvailable)}`}
                        color={
                          calculateAvailableShares(
                            item?.numberOfSharesAuthorized,
                            item?.numberOfSharesAvailable
                          ) <= 10
                            ? COLORS.Red
                            : COLORS.Black
                        }
                      />
                    </div>

                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Eligible Share Class'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading variant='h5' title={`${String(item?.eligibleShareClass ?? '')}`} />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Plan Term'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={`${String(item?.planTerm ?? '')} ${
                          Number(item?.planTerm) > 1 ? 'Years' : 'Year'
                        }`}
                      />
                    </div>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Evergreen Plan'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={(item?.evergreenPlan as boolean) ? 'Yes' : 'No'}
                      />
                    </div>
                    {(item?.evergreenPlan as boolean) && (
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Evergreen Criteria'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading variant='h5' title={(item?.evergreenCriteria as boolean) || '-'} />
                      </div>
                    )}
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Dividend Eligibilty'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading
                        variant='h5'
                        title={(item?.dividentEligibility as boolean) ? 'Yes' : 'No'}
                      />
                    </div>
                  </PlanContainerDetails>

                  {Boolean(item) && item?.planTypeCategories?.includes('Options') === true && (
                    <PlanContainerDetails className='grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2'>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Option Expiration Term'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={getDaysOrYearValue(
                            setDashAsEmptyValue(item?.optionExpirationDate),
                            'Year'
                          )}
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Death'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={getDaysOrYearValue(
                            setDashAsEmptyValue(item?.deathExpirationOption),
                            'Day'
                          )}
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Disability'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={getDaysOrYearValue(
                            setDashAsEmptyValue(item?.disablityExpirationOption),
                            'Day'
                          )}
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Retirement'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={getDaysOrYearValue(
                            setDashAsEmptyValue(item?.retirementExpirationOption),
                            'Day'
                          )}
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Voluntary Termination'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={
                            item?.volutaryTerminationOption === null ||
                            item?.volutaryTerminationOption === '' ||
                            item?.volutaryTerminationOption === undefined
                              ? '-'
                              : Number(item?.volutaryTerminationOption) > 1
                              ? item?.volutaryTerminationOption + ' Days'
                              : item?.volutaryTerminationOption + ' Day'
                            // getDaysOrYearValue(
                            // setDashAsEmptyValue(item?.volutaryTerminationOption),
                            // 'Day'
                          }
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='Involuntary Termination'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={getDaysOrYearValue(
                            setDashAsEmptyValue(item?.involutaryTerminationOption),
                            'Day'
                          )}
                        />
                      </div>
                      <div>
                        <Heading
                          variant='body-xs'
                          className='whitespace-nowrap'
                          title='With A Cause'
                          color={`${COLORS.Gray}`}
                        />
                        <Heading
                          variant='h5'
                          className='pt-2'
                          title={
                            item?.withACause === null ||
                            item?.withACause === '' ||
                            item?.withACause === undefined
                              ? '-'
                              : Number(item?.withACause) > 1
                              ? item?.withACause + ' Days'
                              : item?.withACause + ' Day'
                          }
                        />
                      </div>
                    </PlanContainerDetails>
                  )}

                  {(item?.description as boolean) && (
                    <PlanContainerDetails>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Description'
                        color={`${COLORS.Gray}`}
                      />
                      <Heading variant='h5' className='pt-2' title={item?.description} />
                    </PlanContainerDetails>
                  )}
                  <PlanContainerDetails>
                    <Heading
                      variant='body-xs'
                      className='whitespace-nowrap'
                      title='Plan Documents'
                      color={`${COLORS.Gray}`}
                    />
                    <PlanUploadedDetails className='mt-2'>
                      {item?.planDocuments?.map((documents: any) => (
                        <ViewUploadedDoc
                          variant='info'
                          icon={'draft'}
                          size={'sm'}
                          className={'flex flex-row cursor-pointer'}
                          handleOnClick={() => {
                            handleImageOpen(documents?.planDocumentUniqueName, documents?.planId);
                          }}>
                          <Heading
                            variant={'body-s'}
                            title={documents?.planDocumentFileName}
                            className='break-all'
                          />
                        </ViewUploadedDoc>
                      ))}
                    </PlanUploadedDetails>
                  </PlanContainerDetails>
                  <PlanContainerDetails>
                    <Heading
                      variant='body-xs'
                      className='whitespace-nowrap'
                      title='Plan Grant Documents'
                      color={`${COLORS.Gray}`}
                    />
                    <PlanUploadedDetails className='mt-2'>
                      {item?.planGrantDocuments?.map((documents: any) => (
                        <ViewUploadedDoc
                          variant='info'
                          icon={'draft'}
                          size={'sm'}
                          className={'flex flex-row cursor-pointer'}
                          handleOnClick={() => {
                            documents?.documentTypeId === 4
                              ? openInNewTab(documents?.termsAndConditionText)
                              : handlePlanGrantDocumentImageOpen(
                                  documents?.documentId,
                                  item?.planId
                                );
                          }}>
                          <Heading
                            variant={'body-s'}
                            title={documents?.documentName}
                            className='break-all'
                          />
                        </ViewUploadedDoc>
                      ))}
                    </PlanUploadedDetails>
                  </PlanContainerDetails>
                  <Divider />
                  <PlanContainerDetails className='grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2'>
                    <div>
                      <Heading
                        variant='body-xs'
                        className='whitespace-nowrap'
                        title='Approval Status'
                        color={`${COLORS.Gray}`}
                      />
                      <StatusBox
                        status={
                          item.planApprovalStatus === 'Approved'
                            ? 'Approved'
                            : item.planApprovalStatus === 'Rejected'
                            ? 'Rejected'
                            : 'Pending'
                        }>
                        <span className='material-symbols-outlined'>
                          {item.planApprovalStatus === 'Approved'
                            ? 'check_circle'
                            : item.planApprovalStatus === 'Rejected'
                            ? 'cancel'
                            : 'exclamation'}
                        </span>
                        <Heading
                          variant='h5'
                          title={`${String(item.planApprovalStatus ?? '')}`}
                          color={
                            item.planApprovalStatus === 'Approved'
                              ? COLORS.Green
                              : item.planApprovalStatus === 'Rejected'
                              ? COLORS.Red
                              : COLORS.Iris
                          }
                        />
                      </StatusBox>
                      {/* <Heading
                          variant='h5'
                          className='pt-2'
                          title={
                            <Tag
                              variant={
                                item.planApprovalStatus === 'Approved'
                                  ? 'success'
                                  : item.planApprovalStatus === 'Rejected'
                                    ? 'error'
                                    : 'info'
                              }
                              title={`${item.planApprovalStatus}`}
                            />
                          }
                        /> */}
                    </div>
                    <div className='col-span-4'>
                      {item.planApprovalStatus === 'Rejected' && (
                        <>
                          <Heading
                            variant='body-xs'
                            title='Rejected Reason'
                            color={`${COLORS.Gray}`}
                          />
                          <Heading variant='h5' className='pt-2' title={item.remarks} />
                        </>
                      )}
                    </div>
                  </PlanContainerDetails>
                </PlanContainer>
              );
            })}
          </>
        ) : (
          <div className='mt-[150px]'>
            <NoDataFound message='No Data Found' />
          </div>
        ))}

      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={'Delete'}
          title={'this plan'}
          handleSubmit={handleDelete}
          loading={loading}
        />
      )}
      <AnimationWrapper>
        {isOpenGrant && (
          <GrantManagementModal
            awardLoader={awardLoader}
            isModalOpen={isOpenGrant}
            closeModal={closeGrantModal}
            planInitialData={planInitialData}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            initialData={initialValues}
          />
        )}
      </AnimationWrapper>

      <AnimationWrapper>
        {isModalOpen && (
          <PlanManagementModel
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            planInitialData={planInitialData}
            setPlanInitialData={setPlanInitialData}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default PlanManagement;
